.container-passed-failed{
    width: 100%;
    padding:  0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container-title-passed{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-title-passed p{
    margin: 0;
    font-size: 90%;
}
.container-title-passed span{
    font-weight: 600;
    letter-spacing: 0px;
    color: #00C14F;
    margin: 0;
    font-size: 90%;
}
.container-title-failed{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-title-failed p{
    margin: 0;
    font-size: 90%;
}
.container-title-failed span{
    font-weight: 600;
    letter-spacing: 0px;
    color: #E6001D;
    margin: 0;
    font-size: 90%;
}