h3 {
  overflow: hidden;
}
h3:after {
  content: '';
  display: inline-block;
  width: 89%;
  margin-left: 10px;
  border-bottom: 1px solid #21a0d2;
}
.BDivider.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em 0 0;
}
