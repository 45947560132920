/* :root {
  --error-color: #dc3545;
  --success-color: #28a745;
} */

.actions-container {
  display: flex;
}

.action-container {
  font-weight: bold !important;
  display: block;
}

.action-container:first-child {
  margin-right: 1rem;
}

.action-container.rejected p {
  color: var(--error-color);
}
.action-container.rejected button {
  color: white !important;
  background-color: var(--error-color) !important;
  border-color: var(--error-color) !important;
}

.action-container.approve p {
  color: var(--success-color);
}

.action-container.approve button {
  color: white !important;
  background-color: var(--success-color) !important;
  border-color: var(--success-color) !important;
}

.review-challenge {
  display: inline-flex;
  top: -31px;
  position: absolute;
  width: auto;
  left: 0;
}
.sales-challenge {
  display: inline-flex;
}
.review-challenge p {
  font-size: 12px;
}

.container-modal-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #00499a;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.container-modal-title p {
  margin: 0 0 0 10px;
}

.container-modal-text {
  margin-left: 26px;
}

.container-modal-buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
