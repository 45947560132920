.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
  overflow: auto;
}
.content {
  position: relative;
  top: 43px;
  left: 50%;
  width: 650px;
  min-height: 830px;

  transform: translateX(-50%);
}
.closeX {
  position: absolute;
  top: 30px;
  left: calc(50% + 324px);
  font-size: 24px;
  font-weight: 700;
  color: rgb(240, 240, 240);
  cursor: pointer;
  filter: drop-shadow(1px 1px 4px rgb(0, 0, 0, 1));
}
