.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 220px;

}
.container p{
    text-align: center;
    font-size: 14px;
    margin-bottom: 3px;
}

.progress-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.progress{
    height: 35px;
    width: calc(75%/10);
}