:root {
  --info-color: #0078b3;
}
.bcard .ant-card-body {
  background: transparent;
  cursor: initial;
}

.bcard.ant-card-hoverable {
  cursor: default;
}

.bcard .ant-card-body {
  background: #fff;
  color: var(--gray-dark);
  font-weight: 600;
}

.bcard-agreements {
  min-width: 190px;
}

.bcard .ant-card-head {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px 8px 0px 0px;
}

.bcard-default-header .ant-card-head {
  background: var(--main-theme-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.bcard-info-header .ant-card-head {
  background: var(--info-color) !important;
}

.bcard-initial-header .ant-card-head {
  background: #fff !important;
  color: #000000d9;
}

.bcard-right-column-header .ant-card-head {
  background-color: var(--blue-3) !important;
  color: #fff;
}

h3:after {
  border: none;
}

/* .bcard .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.bcard .ant-skeleton {
  margin-top: 2rem !important;
}

.bcard .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-bottom: 0.5em !important;
  margin-top: 0 !important;
} */
