.container-loading{
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.289);
}