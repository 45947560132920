.externalTopBar {
  background: #00499a;
  padding: 7px 50px;
  max-height: 50px;
}
.externalContentBox {
  padding: 15px 20px 15px 70px;
  height: calc(100vh - 50px);
  overflow-x: auto;
  overflow-y: auto;
}
.externalTitle {
  color: #fff;
  margin-right: auto;
  line-height: normal;

  font-size: 1.3em;
  font-weight: bold;
  margin-top: 10px;
}
