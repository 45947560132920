.catalog-Card {
  background-color: white;
  border: 4px solid rgb(0, 73, 154);
}

.catalog-card-footer {
  display: Flex;
  background-color: rgb(0, 73, 154);
}

.catalog-card-footer-action {
  width: calc(100% / 3);
  margin: 10px 0px;
  text-align: center;
  font-size: 1.5em;
  border-right: 1px solid white;
}
.catalog-card-footer-action:last-child {
  border-right: 0px solid white;
}

.catalog-card-title {
  color: rgb(0, 73, 154);
  font-weight: 700;
  font-size: 1.1em;
}
.catalog-card-body {
  padding: 5%;
  height: 90px !important;
}
