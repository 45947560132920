@media print {
    button,
    header,
    .position-sidebar,
    .ant-drawer-open,
    .ant-pagination {
      display: none !important;
    }
  
    .comparative-last-row td {
      border: none !important;
    }
    .children-wrapper {
      padding-left: 0px !important;
    }
  
    #root {
      width: 900px !important;
    }
  
   
  }
  