.modal-dash-Btn-c {
    display: flex;
    justify-content: center;
}

.modal-dash-Btn-c Button:first-child {
    margin-right: 1rem;
}

.excel-Btn {
    background-color: #0078B3;
    color: #F9F9F9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20%;
    height: 20%;
    border-radius: 5px;
}

.img-Btn:hover {
    background-color: #00499A;
    color: #F9F9F9;
}
.excel-Btn:hover {
    background-color: #0078B3;
    color: #F9F9F9;
}

.img-Btn:active {
    background-color: #00499A;
    color: #F9F9F9;
}
.excel-Btn:active {
    background-color: #0078B3;
    color: #F9F9F9;
}

.img-Btn {
    background-color: #00499A;
    color: #F9F9F9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20%;
    height: 20%;
    border-radius: 5px;
}

.img-Btn.mr {
    margin-right: 1rem;
}
