@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  height: 100vh !important;
  overflow-y: hidden;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
p,
ul,
li {
  font-family: 'Montserrat';
}
h1,
h2,
h3,
h4 {
  /* color: #474747 !important;
  font-weight: 900 !important; */
  text-align: center;
}
.ant-btn-danger {
  background-color: #c50022 !important;
  border-color: #c50022 !important;
}

.ant-btn-info {
  border-color: #004a96 !important;
  color: #fbfbfb !important;
  background-color: #004a96 !important;
}

.ant-btn-primary {
  background-color: #004a96 !important;
  border-color: #004a96 !important;
  color: #fbfbfb !important;
}

.ant-btn-primary:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
}
.module-card {
  background-color: #fbfbfb !important;
  padding: 25px;
}
/* 4em */
.module-card-transparent {
  padding: 25px;
  margin: 0;
}

/* Magic Search provitional css */

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 2.5em;
  width: 90%;
  padding: 0 8px;
  margin-left: 5%;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 2px;
  background-color: #fefeff;
}
.tags-global {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 2.5em;
  width: 90%;
  padding: 0 8px;
  margin-top: 5px;
  margin-left: 5%;
  border-radius: 2px;
}

.tags-input:focus-within {
  border: 1px solid #0052cc;
}
.query-input {
  flex: 1 1;
  border: none;
  height: 2.1em;
  font-size: 14px;
  padding: 4px 0 0 0;
}
.tags-input:focus-within > input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 4px 0 0 0;
}

.tag {
  width: auto;
  height: 2.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fefeff;
  padding: 0 8px;
  font-size: 0.9em;
  list-style: none;
  font-weight: 200;
  border-radius: 1px;
  margin: 0 4px 4px 0;
}
.tag > .tag-close-icon {
  display: block;
  width: 0.9em;
  height: 0.9em;
  line-height: 16px;
  text-align: center;
  font-size: 1.2em;
  margin-left: 8px;
  cursor: pointer;
}
.tag.parameter {
  background-color: #0052cc;
}
.tag.operator {
  background-color: #00cc7a;
}
.tag.value {
  background-color: #cc7a00;
}
.tag.global {
  background-color: #dedede;
}
.tag.global > span,
.tag.global > i {
  color: #101010;
  font-weight: 600;
}
.suggestions-wrapper {
  padding: 0.5em;
  margin: 0px 5% 0px;
  width: 90%;
  border-radius: 0px 0px 3px 3px;
  display: flex;
  justify-content: center;
  background-color: rgba(251, 251, 251, 0.5);
}
.suggestions {
  width: 20%;
  border: 1px solid;
  margin: 0px 10px;
  border-radius: 2px;
}
.suggestions > div > ul {
  list-style: none;
  padding-left: 1em;
  margin: 1em -1em;
}
.suggestions > .suggestions-column-header {
  height: 1.7rem;
}
.suggestions.parameter > .suggestions-column-header {
  background-color: rgba(0, 82, 204, 0.8);
}
.suggestions.operator > .suggestions-column-header {
  background-color: rgba(0, 204, 122, 0.8);
}
.suggestions.value > .suggestions-column-header {
  background-color: rgba(204, 122, 0, 0.8);
}
.suggestions > .suggestions-column-body {
  padding: 5px 15px;
}
.suggestions.parameter {
  border-color: #0052cc;
}
.suggestions.operator {
  border-color: #00cc7a;
}
.suggestions.value {
  border-color: #cc7a00;
}
.suggestions > div > p {
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-size: 1.2em;
  color: #fbfbfb;
}
.suggestions > div > ul > li {
  margin-bottom: 0.1em;
}
.suggestions > div > ul > li > div > span {
  font-size: 1.1em;
  font-weight: 700;
}
.suggestions > div > ul > li > div > span:hover {
  cursor: pointer;
}
.suggestions.parameter > div > ul > li > div > span:hover {
  color: #0052cc;
}
.suggestions.parameter > div > ul > li > div > span:active {
  color: #0052cc;
}
.suggestions.operator > div > ul > li > div > span:hover {
  color: #00cc7a;
}
.suggestions.operator > div > ul > li > div > span:active {
  color: #00cc7a;
}
.suggestions.value > div > ul > li > div > span:hover {
  color: #cc7a00;
}
.suggestions.value > div > ul > li > div > span:active {
  color: #cc7a00;
}

.ant-select-multiple .ant-select-selection-item-content {
  font-size: 0.8em;
}
.ant-select-multiple .ant-select-selection-item {
  padding: 0px 4px;
}

.ant-table-thead > tr > th {
  text-align: center;
}

.ant-modal-content {
  background-clip: padding-box;
  border: none !important;
  border-radius: 2px;
  box-shadow: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #7ab0d6 !important;
}

.modal__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  margin: 2em 0;
}

.modal__footer .ant-btn {
  width: 200px;
  font-weight: bold;
}

.modal__footer .ant-btn .anticon {
  display: none;
}

.modal__footer .ant-btn.report-btn {
  background: #0078b3 !important;
}

.ant-btn .delete-catalog__button {
  background: transparent;
  color: blue;
}

.ant-modal.download__modal h4 {
  color: #00499a;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}

.ant-modal.download__modal p {
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
}

.radio-wraper .ant-radio-group.download-types {
  padding: 1em;
  box-sizing: border-box;
}

.ant-radio-group label {
  display: block;
  margin: 0 0 0.5em;
}

.radio-wraper {
  width: 80%;
  margin: 1em auto;
}

.radio-wraper .ant-radio:hover,
.radio-wraper .ant-radio-inner {
  width: 18px;
  height: 18px;
  border-color: #00499a !important;
  border-width: 2px !important;
}

.radio-wraper .ant-radio-inner::after {
  background: #00499a;
}

.radio-wraper h4 {
  text-align: left;
}

.ant-modal.sumary-duplicated .ant-modal-confirm .ant-modal-confirm-btns {
  float: none !important;
  margin-top: inherit !important;
  display: flex;
  justify-content: space-around;
}

.reject-holder {
  position: absolute !important;
  right: 1.5em;
  bottom: 1.75em;
}

.reject-holder .reject-holder__label {
  display: block;
  color: red !important;
}

.reject-holder button {
  background: red;
  border: none;
  color: white;
  border-radius: 50%;
  margin: 0 0.75em;
}

.reject-holder button:hover {
  background: red;
  color: white;
}

.reject-holder .ant-btn:active,
.reject-holder .ant-btn:focus,
.reject-holder .ant-btn:hover {
  color: white;
  background: red;
  border-color: transparent;
}

:root {
  /* --warning-color: ; */

  --blue-1: #ebf5fc;
  --blue-2: #c6dff1;
  --blue-3: #7ab0d6; /*input label*/
  --blue-4: #0078b3; /*table columns titles*/
  --blue-5: #00499a; /*background header,footers cards*/
  --gray-light: #00000029; /*borders*/
  --gray-dark: #707070; /*text*/
  --body-bg: var(--blue-1);
  --semaphore-green: #007a32;
  --semaphore-red: #e6001d;
  --sempahore-yellow: #ff9600;
  --text-semaphore-green: #00c14f;
  --text-sepahore-red: #e6001d;
  --main-theme-color: var(--blue-5);
  --success-color: #28a745;
  --error-color: #dc3545;
  --info-color: var(--blue-4);
  --status-on-process: var(--blue-3);
  --status-on-validation: #b55cff;
  --status-approved: var(--semaphore-green);
  --status-rejected: var(--semaphore-red);
  --status-partially-rejected: #f16800;
  --status-on-second-validation: var(--sempahore-yellow);
  /* color para el estatus de segundo nivel de validacion */
  --status-on-second-level-validation: #0ebd96;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(99, 97, 97, 0.671)),
    color-stop(0.72, #bfbfbf),
    color-stop(0.86, rgba(99, 97, 97, 0.671))
  );
}

::-webkit-scrollbar {
  border-radius: 12px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(99, 97, 97, 0.671)),
    color-stop(0.72, #bfbfbf),
    color-stop(0.86, rgba(99, 97, 97, 0.671))
  );
}

body,
.ant-layout {
  color: #707070;
  color: var(--gray-dark);
  background: #ebf5fc;
  background: var(--body-bg);
  /* font-family: 'Montserrat';
  font-weight: 300; */
}
.ant-layout-header,
.ant-layout-sider {
  background: #00499a;
  background: var(--blue-5);
}
label {
  color: #7ab0d6 !important;
  color: var(--blue-3) !important;
  font-weight: 400;
}
.ant-select {
  color: #707070;
  color: var(--gray-dark);
}

.ant-form-item-label {
  text-align: left;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #707070;
  color: var(--gray-dark);
}

button {
  font-weight: 400;
}

button.ant-btn.ant-btn-default:not([disabled]) {
  background: #0078b3;
  color: #fff;
}

button:disabled {
  color: #707070;
  color: var(--gray-dark);
  cursor: not-allowed;
}

.ant-form-item-label {
  display: inline-flex;
}

.ant-form-item-label > label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
}

.element-registered {
  border-left: 3px solid #007a32;
  border-left: 3px solid var(--semaphore-green);
}
/* .ant-notification-notice-message {
  text-align: justify;
} */

.loader-container {
    display: block;
    margin-top: calc(50vh - 100px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
#businessEstructureFilters {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}
#archiveBussinesEstructureSelect > div {
  min-width: 130px;
  max-width: 170px;
  margin-left: 5px;
}
.ant-table-wrapper::after {
  display: none !important;
}
#archiveBussinesEstructureSelect .ant-table-content {
  min-height: 525px;
  min-height: 525px;
  overflow: scroll;
}
.ant-btn.delete-catalog__button {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  box-shadow: none;
  cursor: pointer;
}

.ant-btn.delete-catalog__button svg {
  color: #004a96;
}

.btn-add-user,
.btn-upload {
  margin-right: 5px;
  color: white !important;
  background-color: #003d81 !important ;
  text-align: center;
}
.btn-add-user:disabled,
.btn-upload:disabled {
  color: white !important;
  background-color: #8f8f8f !important ;
}

.btn-action-button {
  margin: -0.5rem;
}
.estatus-switch {
  color: #8f8f8f;
}
/*Orange Switch*/
.half-switch {
  background-color: #ff9600 !important;
}
.half-switch span {
  color: rgba(255, 255, 255, 0) !important;
}
.half-switch .ant-switch-handle {
  left: calc(50% - 9px) !important;
}


/* width */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar {
    width: 10px;
}

/* Track */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
h3 {
  overflow: hidden;
}
h3:after {
  content: '';
  display: inline-block;
  width: 89%;
  margin-left: 10px;
  border-bottom: 1px solid #21a0d2;
}
.BDivider.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em 0 0;
}

.box-component_title-container__ccUHp{
    width: 100%;
    height: 35%;
    background-color: #0078B3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
}

.box-component_title-container__ccUHp p{
    font-size: 1.2rem; 
    margin: 0;
}
.box-component_children-container__1hztl{
    width: 100%;
    height: 65%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.client-passed-failed_container-passed-failed__jgO-0{
    width: 100%;
    padding:  0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.client-passed-failed_container-title-passed__Q7SxE{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.client-passed-failed_container-title-passed__Q7SxE p{
    margin: 0;
    font-size: 90%;
}
.client-passed-failed_container-title-passed__Q7SxE span{
    font-weight: 600;
    letter-spacing: 0px;
    color: #00C14F;
    margin: 0;
    font-size: 90%;
}
.client-passed-failed_container-title-failed__335Vw{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.client-passed-failed_container-title-failed__335Vw p{
    margin: 0;
    font-size: 90%;
}
.client-passed-failed_container-title-failed__335Vw span{
    font-weight: 600;
    letter-spacing: 0px;
    color: #E6001D;
    margin: 0;
    font-size: 90%;
}
.percentage_container__15HxM{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 130px;
}

.percentage_title__2k8iZ{
    font-size: 14px;
    margin-bottom: 2px;
}

.percentage_battery__34Xlb{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 35px;
}

.percentage_battery__34Xlb p{
    font-size: 14px;
    margin: 1px 0 0 0;
}

.percentage_container-battery__2Kqno{
    border-bottom: 4px solid #969696b3;
    border-top: 4px solid #969696b3;
    width: 40%;
    height: 100%;
    max-height: 40px;
    max-width: 60px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    margin: 0 5px 0 12px;
}

.percentage_percentage-battery__3_G-Y{
    height: 100%;
    width: calc(80%/4);
    max-width: 15px;
}

.percentage_percentage-battery-last__16tgt{
    height: 13px;
    width: 7%;
    max-width: 7px;
}
.days-remaining_container__NI-Ct{
    width: 110px;
}
.days-remaining_container__NI-Ct p{
    font-size: .9rem;
    margin: 0;
}


.days-remaining_container__NI-Ct span{
    color: red;
    font-weight: bold;
}
.progress-percentage_container__p-6p8{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 220px;

}
.progress-percentage_container__p-6p8 p{
    text-align: center;
    font-size: 14px;
    margin-bottom: 3px;
}

.progress-percentage_progress-container__11X3V{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.progress-percentage_progress__2qXOX{
    height: 35px;
    width: calc(75%/10);
}
.loading_container-loading__3dC93{
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.289);
}
.label_container-label__6dcKn{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    margin: 12px 0;
}
:root {
  --info-color: #0078b3;
}
.bcard .ant-card-body {
  background: transparent;
  cursor: auto;
  cursor: initial;
}

.bcard-sales .ant-card-body {
  height: 63px;
  border-radius: 0px !important;
  padding: 0px !important;
}

.bcard.ant-card-hoverable {
  cursor: default;
}

.bcard .ant-card-body {
  background: #fff;
  color: var(--gray-dark);
  font-weight: 600;
}

.bcard-agreements {
  min-width: 190px;
}

.bcard-sales .ant-card-head {
  min-width: 190px;
  border-radius: 0px !important;
}

.bcard .ant-card-head {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px 8px 0px 0px;
}

.bcard-default-header .ant-card-head {
  background: var(--main-theme-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.bcard-info-header .ant-card-head {
  background: #0078b3 !important;
  background: var(--info-color) !important;
}

.bcard-initial-header .ant-card-head {
  background: #fff !important;
  color: #000000d9;
}

.bcard-right-column-header .ant-card-head {
  background-color: var(--blue-3) !important;
  color: #fff;
}

h3:after {
  border: none;
}

/* .bcard .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.bcard .ant-skeleton {
  margin-top: 2rem !important;
}

.bcard .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-bottom: 0.5em !important;
  margin-top: 0 !important;
} */

.btable-agreements .ant-table-cell {
  font-size: 0.7em;
  color: var(--gray-dark);
  font-weight: 600;
  padding: 2px;
}
.btable-agreements .ant-table-content {
  min-height: 325px;
}

.btable-agreements {
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition-property: box-shadow, border-color;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
}

.btable-agreements tbody tr td:first-child {
  padding-left: 20px !important;
}

.style_externalTopBar__6MqTl {
  background: #00499a;
  padding: 7px 50px;
  max-height: 50px;
}
.style_externalContentBox__10sXD {
  padding: 15px 20px 15px 70px;
  height: calc(100vh - 50px);
  overflow-x: auto;
  overflow-y: auto;
}
.style_externalTitle__GEK9t {
  color: #fff;
  margin-right: auto;
  line-height: normal;

  font-size: 1.3em;
  font-weight: bold;
  margin-top: 10px;
}

/* :root {
  --error-color: #dc3545;
  --success-color: #28a745;
} */

.actions-container {
  display: flex;
}

.action-container {
  font-weight: bold !important;
  display: block;
}

.action-container:first-child {
  margin-right: 1rem;
}

.action-container.rejected p {
  color: var(--error-color);
}
.action-container.rejected button {
  color: white !important;
  background-color: var(--error-color) !important;
  border-color: var(--error-color) !important;
}

.action-container.approve p {
  color: var(--success-color);
}

.action-container.approve button {
  color: white !important;
  background-color: var(--success-color) !important;
  border-color: var(--success-color) !important;
}

.review-challenge {
  display: inline-flex;
  top: -31px;
  position: absolute;
  width: auto;
  left: 0;
}
.sales-challenge {
  display: inline-flex;
}
.review-challenge p {
  font-size: 12px;
}

.container-modal-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #00499a;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.container-modal-title p {
  margin: 0 0 0 10px;
}

.container-modal-text {
  margin-left: 26px;
}

.container-modal-buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login_login-wrapper__2vKe1 {
  background-color: #00499a;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 5rem 5rem 0px 0px;
  background-size: cover;
}
.login_logo__1EIdB {
  height: 0;
  width: 400px;
  background-color: #00499a;
  float: left;
  padding: 50px 100px 100px 115px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.login_login-box__1uAaM {
  position: fixed;
  right: 10vw;
  background-color: #fefefe;
  width: 30vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: 70vh;
  max-height: 450px;
  padding: 25px;
  text-align: center;
  letter-spacing: 0.1em;
}
.login_login-box__1uAaM > h1,
.login_login-box__1uAaM > h2,
.login_login-box__1uAaM > span,
.login_login-box__1uAaM > p {
  color: #00499a;
  font-family: 'Montserrat', sans-serif;
}
.login_login-box__1uAaM > h1 {
  font-weight: 700;
  margin: 0px 0px 15px 0px;
}
.login_login-box__1uAaM > h2 {
  font-weight: 500;
  margin: 0px 0px 35px 0px;
}
.login_login-box__1uAaM > p {
  color: #474747;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  margin-left: 0.5em;
  margin-bottom: 0.3em;
}
.login_login-box__1uAaM > span {
  text-align: end;
  margin: 0px 0px 1.4rem 0px;
  font-size: 1.3em;
}
.login_login-box__1uAaM > button {
  float: right;
  background: #00499a;
  border: none;
  width: 120px;
  margin: 2rem 0px;
  padding: 0.2em 0.1em 0.3em 0.1em;
  color: white;
  font-size: 1.1em;
  border-radius: 1px;
  cursor: pointer;
}
.login_login-btn_disabled__2C14b {
  background: rgba(0, 73, 154, 0.5) !important;
  cursor: pointer;
}

.login_span-reset-password__1S_24 {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.login_span-reset-password__1S_24 button {
  background-color: white;
  border: none;
  color: #00499a;
  font-size: 12px !important;
  text-decoration: underline;
  margin: 0 !important;
  cursor: pointer;
}
.login_description-reset-password__179ER {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.login_description-reset-password__179ER p {
  color: #00499a;
  font-size: 12px !important;
}

.login_container-icon-back__1u3fT {
  display: flex;
  justify-content: flex-start;
}

.restore_container-modal__3nxad {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.restore_container-modal-title__1qMxA {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00499a;
  margin-bottom: 20px;
  font-weight: bold;
}
.restore_container-modal-title__1qMxA span {
  font-size: 18px;
  margin: 0 20px 0 0;
}
.restore_container-modal-title__1qMxA p {
  font-size: 18px;
  margin: 0;
}
.restore_container-modal-text__1_p0f {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}
.restore_container-text__EprJn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.restore_container-paragraph__1vRWV {
  margin-top: -15px;
  text-justify: initial;
}
.restore_container-form__3-eB8 {
  display: flex;
  align-items: center;
  width: 70%;
}

.restore_container-pass__3EiIY {
  border: solid 2px blue;
  border-radius: 5px;
  margin-bottom: 15px;
}

.public_browserBtn__3rQah {
  margin-left: calc(90vw - 202px);
  margin-right: 10vw;
  transform: translateY(-15px);
  margin-bottom: -15px;
  z-index: 2;
}
.public_mobileBtn__1uUbm {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50vh;
  left: 50vw;
}
.public_containerStyle__YmB-U {
  margin: 0;
  margin-top: 15px;
  padding: 0;
  padding-bottom: 80px;
}
.public_pdfStyle__1vOrd {
  margin-top: 7px;
  width: 70vw;
  min-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.public_activePdfBackground__owXI7 {
  background-color: rgb(112, 112, 112);
}

.public_btn__qndAE {
  letter-spacing: 2px;
  font-weight: 600;
  fill: white !important;
}
.public_icon__2-OJI {
  filter: invert(100%) sepia(1%) saturate(6945%) hue-rotate(119deg) brightness(114%) contrast(100%);
  margin-right: 5px;
  transform: translateY(2px);
}

.ant-layout-content {
  background-color: #ebf8ff;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item {
  margin: 0;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-active {
  background: #002140;
  color: white;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 1.25rem !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 1.25rem !important;
}

.ant-menu-inline-collapsed {
  width: 55px;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected,
.sidenav-footer:hover,
.sidenav-footer-collapsed:hover {
  background: #002140;
  color: white;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #002140;
  color: white;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0 !important;
}

.sidenav-wrapper {
  padding: 2rem;
  height: 90%;
}
.sidenav-wrapper-collapsed {
  padding: 1rem;
  height: 90%;
}
.sidenav-header {
  text-align: center;
}
.sidenav-logo {
  width: 100%;
}
.sidenav-body {
  height: 100%;
  margin-top: 25px;
}
.sidenav-footer,
.sidenav-footer-collapsed {
  border-top: 1px solid white;
  bottom: 60px;
  cursor: pointer;
  padding-bottom: 0.5rem;
  padding-top: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 100ms linear background-color;
  width: 100%;
}
.sidenav-footer {
  text-align: start;
  padding-left: 1.3rem;
}
.sidenav-footer-collapsed {
  margin-left: -1rem;
}
.collapse-button {
  border: none;
  background: none;
  color: white;
  outline: none;
  width: 110%;
  margin-left: -10px;
  cursor: pointer;
}
.collapsed-collapse-button {
  border: none;
  background: none;
  color: white;
  outline: none;
  margin-left: -5px;
  cursor: pointer;
}
.logout-button {
  background: none;
  cursor: pointer;
  border: none;
  color: white;
  outline: none;
}
.nav-item,
.nav-item-collapse {
  /* border-right: 2px solid white; */
  margin-top: 0.3rem;
  line-height: 0.2em;
}
.nav-item > a,
.nav-item-collapse > a {
  width: 100%;
  color: #fbfbfb;
}
.nav-item > a > p,
.nav-item-collapse > a > p {
  margin: 1em 0px 1.2em 0px;
  line-height: 1.3em;
}
.nav-item-collapse {
  text-align: center;
  margin: 10px 0px;
}

.nav-container-subitem {
  position: relative;
  width: 180px;
  right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #003d81;
  padding: 0.9em 0px 0.5em 55%;
}

.nav-container-subitem a {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}
.nav-container-subitem > p {
  padding-left: 30%;
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}

.nav-subitem > p {
  padding-left: 30%;
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}
.nav-subitem-collapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #003d81;
  position: absolute;
  z-index: 20;
  padding: 14px;
  left: 43px;
  top: 98px;
}
.nav-subitem-collapsed a {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
  white-space: nowrap;
}
.nav-subitem-collapsed p {
  margin: 6px 0;
}

.nav-subitem-collapsed > p {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}

.module-nav {
  font-weight: 400;
}
.module-selected {
  color: #f0f2f5 !important;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #0078b3 !important;
}
.vertical-div {
  width: 2px;
  height: auto;
  background-color: #fbfbfb;
  margin: 0px 20px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ant-layout-header {
  height: 50px;
}
.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 3px 15px 3px 0;
  border-right: 2px solid white;
  margin-right: 15px;
}

.navbar-logo-short {
  width: 100px;
}

.navbar-actions {
  margin-left: auto;
  line-height: normal;
}

.notification-icon {
  color: white;
  font-size: 1.3em;
  margin: 10px 0px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #00499a !important;
}

.position-sidebar {
  position: absolute;
  z-index: 102;
  height: calc(100% - 50px);
}

.update-password_continer-breadcrums-profile__2XKJ9 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.update-password_container-profile__3DdYd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.update-password_container-profile__3DdYd p {
  margin: 0;
  color: white;
}
.update-password_profile__3ViRN {
  cursor: pointer;
}

.update-password_continer-update-password__2ftlx {
  position: absolute;
  height: 35px;
  padding: 0 12px;
  top: 48px;
  background-color: white !important;
  border: 2px solid #00499a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.update-password_continer-update-password__2ftlx p {
  color: #00499a;
  margin: 0 !important;
}

.breadcrumbs_breadcrumbs__29R_g > p {
  line-height: normal;
  margin: 0;
  font-size: 1.3em;
  color: white;
}

.container-profile_continer-breadcrums-profile__26ae9 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-profile_container-profile__z3g4Q {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.container-profile_container-profile__z3g4Q p {
  margin: 0;
  color: white;
}

.container-profile_profile__1CqK7 {
  cursor: pointer;
}

.container-profile_continer-update-password__5iyjP {
  position: absolute;
  height: 35px;
  padding: 0 12px;
  top: 48px;
  background-color: white !important;
  border: 2px solid #00499a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}
.container-profile_continer-update-password__5iyjP p {
  color: #00499a;
  margin: 0 !important;
}

.modal-update-password_container-modal__1BakR {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-update-password_container-modal-title__o1rwK {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00499a;
  margin-bottom: 20px;
}
.modal-update-password_container-modal-title__o1rwK span {
  font-size: 18px;
  margin: 0 20px 0 0;
}
.modal-update-password_container-modal-title__o1rwK p {
  font-size: 18px;
  margin: 0;
}
.modal-update-password_container-modal-text__2qn7S {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}
.modal-update-password_container-text__2BPNo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.modal-update-password_container-paragraph__N4CMA {
  margin-top: -15px;
}
.modal-update-password_container-form__1NOVP {
  display: flex;
  align-items: center;
  width: 70%;
}

.modal-update-password_container-pass__UVR-5 {
  border: solid 2px blue;
  border-radius: 5px;
  margin-bottom: 15px;
}

.notifications-list  {
  padding: 10px;
  max-height:350px;
  /* overflow-y: scroll; */
  min-width: 300px;
  overflow-y: scroll;
  margin-top: -1rem;
}
.notifications-list .ant-list-item-meta {
  display: none0
}

.notifications-list .ant-list-item-action {
  /* display: inline-flex;
  text-align: right; */
  top: -43px;
  position: relative;
  margin-left: 2rem;
}

.notification-content {
  text-align: left;
}
.notifications-navbar .notification-content {
  max-width: 330px;
}

.notification-list .ant-list-item {
  padding: 4px 8px;
}

.notification-date {
  font-size: 0.7rem;
  color: var(--gray-dark);
}
.notification-date-two{
  font-size: 0.7rem;
  text-align: left;
  color: var(--gray-dark);
}
.notification-actions {
  float: right;
  margin-top: -5px;
  margin-right: 18px;
}

.notifications-page  .notification-actions-two{
  float: left;
  margin-top: -5px;
  margin-right: 18px;
}

.notification-content .ant-skeleton-paragraph {
  margin-top: 6px !important;
}

.notification-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.notification-content .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 6px;
}

.notification-loading {
  padding: 25px;
}
.notification-loading .ant-skeleton-paragraph {
  margin-top: 10px !important;
}
.notification-loading .ant-skeleton-title {
  margin-top: 10px;
}

.link-style {
font-size: 10px;
color: black;

}

.link-style-content {
  font-size: 15px;
  color: black;
}



.pagination-button{
  display: none;
 
}


.notifications-page .pagination-button{
  display: block;
  text-align: right;
  padding: 10px 45px 15px 0px;
}

.notifications-two {
   
  display: none;
}

.notifications-page  .notifications-two {
 
  display: block;
}

.notifications-page  .notifications-one {
 
  display: none;
}


 .notifications-read {
   
    display: none;
  }

  .notifications-page .notifications-read {
   
    display: block;
  }

  .notifications-page .title-style{
   font-size: 26px;
   padding-top: 20px;
   padding-left: 40px;

  }

  .notifications-page  .button-style{
    padding-top: 20px;
  }

  .link-style-before{
    font-size: 15px;
    color: black;
    }


  


 
.container-notification-button .anticon {
  transition: transform 0.2s ease-in-out;
  will-change: transform;
}

.container-notification-button:hover .anticon {
  transform: scale(1.2);
}

.container-notification-button .ant-badge-count {
  min-width: 14px;
  height: 14px;
  box-shadow: none;
  font-size: 10px;
  line-height: 14px;
}

.container-notification-button{
  padding-left: 20px;
}


.notification-menu {
  position: relative;
  margin: 0px;

  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
}

.notification-menu .ant-list:focus {
  outline: none;
}

.notification-menu h2 {
  background: var(--blue-4);
  text-align: left;
  color: #fff;
  padding: 5px;
  padding-left: 15px;
  font-size: 1.2em;
  margin-top: -3px;
}

.notification-menu h3 {
  color: var(--blue-4);
  padding: 5px 14px;
  margin-bottom: 0px;
}

.notification-menu button {
  text-align: left;
}
.notification-menu .show-all {
  text-align: center;
  font-weight: 700;
}

.dashboard_container-title-dashboard__Zws-E h1{
    font-size: 1.5em; 
    font-weight: 700;
    color: #004A96;
}
.dashboard_img-wrapper__1bo1D img{
    width: 100%;

}

.title-icon-card {
    background-color: white;
    border: 6px solid rgb(0, 73, 154);
    min-height: 8rem;
}

.title-icon-card .ant-row {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 4px;
}

.title-icon-card .title {
    padding: 0 1rem;
    color: rgb(0, 73, 154);
    font-weight: 700;
    font-size: 1.1em;
    text-align: center;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-icon-card .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00499A;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.catalog-card_catalog-Card__sSudN{
    background-color: white;
    border: 4px solid rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer__19VIq {
    display: Flex;
    background-color: rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer-action__2HY_b{
    width: calc(100%/3);
    margin: 10px 0px;
    text-align: center;
    font-size: 1.5em;
    border-right: 1px solid white;
}
.catalog-card_catalog-card-footer-action__2HY_b:last-child{
    border-right: 0px solid white;
}

.catalog-card_catalog-card-title__6mWeT{
    color: rgb(0, 73, 154);
    font-weight: 700;
    font-size: 1.1em;
}
.catalog-card_catalog-card-body__3-Zwy{
    padding: 5%;
}

.form-catalog-input input,
.form-catalog-input label {
  display: block;
}
.form-catalog-input label {
  margin: 0px 0px 3px 5px;
}
.form-catalog-input input {
  width: 100%;
}
.upload-xls-button {
  margin: 16px;
  margin-top: 30px;
  color: white;
  border-radius: 5px;
  width: 220px;
  height: 35px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: #247ba9;
  cursor: pointer;
}

.disabled-upload-xls-button {
  margin: 16px;
  margin-top: 30px;
  color: white;
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
  border-radius: 5px;
  width: 220px;
  height: 35px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: #247ba9;
}

.null div input {
  color: black;
  border-radius: 2px;
  font-weight: 330;
  border: 1px solid rgb(214 213 213) !important;
}
.ant-input-number-input:disabled {
  background-color: #f5f5f5 !important;
  /*color: black;*/
}

.deleteButton {
  position: absolute;
  margin-top: 32px !important;
  margin: 10px 16px 16px;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 220px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: rgb(0, 74, 150);
}

.sumary-duplicated .ant-modal-confirm .ant-modal-confirm-btns {
  float: none !important;
  margin-top: inherit !important;
  display: flex;
  justify-content: space-around;
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.client-registered {
  border-left: 3px solid var(--semaphore-green);
}

thead.businessRules > tr > th.ant-table-cell {
  padding: 8px 16px !important;
}

th.ant-table-cell.anualPlanAchive {
  color: white !important;
  background: #ffb54c !important;
}

th.ant-table-cell.trimPlanAchive {
  color: white !important;
  background: #00c14f !important;
}

th.ant-table-cell.pogDisplaced {
  color: white !important;
  background: #0078b3 !important;
}

th.ant-table-cell.discBase {
  color: white !important;
  background: #7ab0d6 !important;
}

.ant-table-small .ant-table-thead > tr > th {
  color: var(--blue-4);
}

thead.businessRules > tr > th.ant-table-cell {
  padding: 8px 16px !important;
}

th.ant-table-cell.anualPlanAchive {
  color: white;
  background: #ffb54c !important;
}

th.ant-table-cell.trimPlanAchive {
  color: white;
  background: #00c14f !important;
}

th.ant-table-cell.pogDisplaced {
  color: white;
  background: #0078b3 !important;
}

th.ant-table-cell.discBase {
  color: white;
  background: #7ab0d6 !important;
}

.text-help {
  vertical-align: top;
  margin-left: -15px;
  margin-top: 15px;
}

.client-registered {
  border-left: 3px solid var(--semaphore-green);
}

.client-registered {
  border-left: 3px solid var(--semaphore-green);
}

.ant-table-style {
  min-height: 300px;
}

.ant-table-style > tr > th {
  min-height: 300px;
  text-align: right !important;
}

.ant-table-thead,
th.bg-blue {
  background-color: #0078b3 !important;
  color: white !important;
  text-align: left !important;
}

.ant-table-reassign-icon {
  margin-left: 8px;
}

.arrow-before {
  color: white;
}

.ant-table-popover {
  cursor: pointer;
  color: black;
  margin-bottom: 0;
}

.ant-table-popover-second {
  margin-top: 8px;
}

.ant-table-icons {
  cursor: pointer;
  font-size: 18px;
}

.ant-table-column {
  width: 50px;
}

.ant-popover {
  z-index: 999 !important;
}

.switch-active {
  background-color: green;
}

.switch-inactive {
  background-color: red;
}

.icon-disabled {
  color: #707070;
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

.icon-color {
  color: #707070;
  cursor: default;
  opacity: 0.4;
}

.icon-map {
  color: #707070;
  opacity: 0.4;
}

.button-disabled {
  opacity: 0.4;
}

.modal-flex-container {
  display: flex;
  justify-content: center;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.modal-input-container {
  width: 286px;
}

.modal-text-blue {
  color: rgb(33, 160, 210);
}

.modal-title-blue {
  color: #00499a;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.modal-subtitle {
  margin-bottom: 36px;
  margin-left: 65px;
}

.modal-icon {
  font-size: 35px !important;
  color: #00499a;
  padding-right: 30px;
}

.modal-skeleton {
  width: 300px !important;
  height: 300px !important;
}

.modal-skeleton:nth-of-type(1) {
  margin-right: 16px;
}

.modal-skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-container {
  height: 350px !important;
  width: 80vmin !important;
  z-index: 0;
  /*  max-width: 500px;
  height: 300px;
   z-index: 0;
  position: absolute; */
}

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: #0078b3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  content: 'Cargar archivos';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}


.file-select input[type='file'] {
  opacity: 0;
  width: 272px;
  height: 32px;
  display: inline-block;
}

#src-file1::before {
  content: 'Cargar archivos';
}

#src-file2::before {
  content: 'Aceptar';
}

.file-select2::before {
  background-color: #004a96;
  color: white;
  width: 30px;
  height: 20px;
  padding: 7px 15px;
  margin: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  content: 'Cargar archivos';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
}
.file-select2 input[type='file'] {
  background-color: #0078b3;
  color: black;
  opacity: 0;
  max-width: 100px;
  margin-top: -27px;
  /* margin-right: 3; */
  height: 32px;
  display: flex;
  z-index: 1;
}

.icon-disabled {
  color: #707070;
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.ant-modal-title {
  color: #00499a;
}

.ant-input-number {
  border: 0px solid #d9d9d9;
  -webkit-appearance: none;
}

.ant-input-number:hover {
  border-color: #d9d9d9;
}

.ant-input-number-focused {
  border: 0px solid #d9d9d9;
  -webkit-appearance: none;
}

.ant-input-number {
  width: 100%;
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.catalog-card_catalog-Card__2mNhd{
    background-color: white;
    border: 4px solid rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer__ewgls {
    display: Flex;
    background-color: rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer-action__TAAK4{
    width: calc(100%/3);
    margin: 10px 0px;
    text-align: center;
    font-size: 1.5em;
    border-right: 1px solid white;
}
.catalog-card_catalog-card-footer-action__TAAK4:last-child{
    border-right: 0px solid white;
}

.catalog-card_catalog-card-title__1raJl{
    color: rgb(0, 73, 154);
    font-weight: 700;
    font-size: 1.1em;
}
.catalog-card_catalog-card-body__3dI4F{
    padding: 5%;
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.cards_catalog-Card__2yOov {
  background-color: white;
  border: 4px solid rgb(0, 73, 154);
}

.cards_catalog-card-footer__ODFON {
  display: Flex;
  background-color: rgb(0, 73, 154);
}

.cards_catalog-card-footer-action__3P7Ri {
  width: calc(100% / 3);
  margin: 10px 0px;
  text-align: center;
  font-size: 1.5em;
  border-right: 1px solid white;
}
.cards_catalog-card-footer-action__3P7Ri:last-child {
  border-right: 0px solid white;
}

.cards_catalog-card-title__U6HrV {
  color: rgb(0, 73, 154);
  font-weight: 700;
  font-size: 1.1em;
}
.cards_catalog-card-body__16LDa {
  padding: 5%;
  height: 90px !important;
}

.catalog-card_catalog-Card__3Ebqb{
    background-color: white;
    border: 4px solid rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer__Qsyg8 {
    display: Flex;
    background-color: rgb(0, 73, 154);
}

.catalog-card_catalog-card-footer-action__3EPgQ{
    width: calc(100%/3);
    margin: 10px 0px;
    text-align: center;
    font-size: 1.5em;
    border-right: 1px solid white;
}
.catalog-card_catalog-card-footer-action__3EPgQ:last-child{
    border-right: 0px solid white;
}

.catalog-card_catalog-card-title__oRJnS{
    color: rgb(0, 73, 154);
    font-weight: 700;
    font-size: 1.1em;
}
.catalog-card_catalog-card-body__2jP8b{
    padding: 5%;
}

:root {
  --lime-green: #00c14f;
  --scarlet-red: #e6001d;
}

.container {
  width: 100%;
  max-width: 1280px;
  background: white;
  min-height: 80vh;
  margin: 0 auto;
  padding: 2em;
  box-sizing: border-box;
}

.switchModal {
  width: 100%;
  background: var(--lime-green);
  transition: all 0.3s ease-in-out;
}

button.switchModal[aria-checked='false'] {
  background: var(--scarlet-red);
  transition: all 0.3s ease-in-out;
}

.scheduleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: flex-start;
}

.scheduleRow .ant-space-item:last-child {
  flex: 0 1;
  align-self: center;
  transform: translateY(1em);
}

.scheduleActionBtn {
  border: none;
  background: transparent;
}

.scheduleActionBtn .anticon {
  font-size: 1.75rem;
}

.infoTransfer {
  width: 100%;
}

.timepickerhours .ant-picker-ok {
  display: none !important;
}

.timepickerhours {
  width: 143px !important;
  left: 0.265625px;
  padding: 0px !important;
  top: 35.5938px !important;
}

:root {
  --blue: #004a96;
}

.sendBtn {
  border: none;
  color: var(--blue);
  outline: none;
}

.sendAccountBtn,
.sendAccountBtn:hover {
  border: none;
  background: var(--blue);
  outline: none;
  color: white;
  right: 150px;
  bottom: 30px;
  position: fixed;
}

.pdfContainer .react-pdf__Page {
  width: 100% !important;
  height: 100%;
}

.pdfContainer .ant-row {
  height: 100%;
}

.pdfContainer .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.pdfContainer .react-pdf__Document {
  min-height: 500px;
  height: 100%;
}

.pdfModal .ant-modal-content {
  background-color: transparent !important;
}

.pdfModal .ant-modal-footer {
  border-top: none !important;
}

.pdfModal button.ant-modal-close {
  transform: translate(1em, -0.5em) !important;
}

.pdfModal .ant-modal-close-x {
  display: block;
  font-size: 1.5rem;
  color: white;
}

.pdfBtn {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  top: 40%;
}

.pdfBtn.prev {
  left: -2.25em;
}

.pdfBtn.next {
  right: -2.25em;
}

.pdfBtn .anticon {
  font-size: 2.5rem;
}

.spinnerPdf {
  display: flex;
  justify-content: center;
  margin-top: 55%;
}

#drawerManageImages > .ant-drawer-content-wrapper {
  margin-top: 0px !important;
  width: 30vw !important;
}
.ant-drawer-body {
  padding: 0;
  background-color: #f5f5f7 !important;
}
#spaceManageImage > .ant-space-item {
  margin-bottom: 0px !important;
}
#spaceManageImageDiv > .ant-radio-wrapper {
  display: block;
}
.ant-checkbox {
  margin-left: 20px;
}

span.ant-radio + span {
  color: black !important;
}
.imgDrawerManageImage {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 36%;
  max-width: 64%;
  max-height: 85%;
  z-index: 9999;
}

#drawerManageImages > .ant-drawer-content-wrapper {
  margin-top: 0px !important;
  width: 30vw !important;
}
.ant-drawer-body {
  padding: 0;
  background-color: #f5f5f7 !important;
}
#spaceManageImage > .ant-space-item {
  margin-bottom: 0px !important;
}
#spaceManageImageDiv > .ant-radio-wrapper {
  display: block;
}
.ant-checkbox {
  margin-left: 20px;
}

span.ant-radio + span {
  color: black !important;
}
.imgDrawerManageImage {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 36%;
  max-width: 64%;
  max-height: 85%;
  z-index: 1;
}

.modal-flex-container {
  display: flex;
  justify-content: center;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.modal-input-container {
  width: 286px;
}

.modal-text-blue {
  color: rgb(33, 160, 210);
}

.modal-title-blue {
  color: #00499a;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.modal-subtitle {
  margin-bottom: 36px;
  margin-left: 65px;
}

.modal-icon {
  font-size: 35px !important;
  color: #00499a;
  padding-right: 30px;
}

.modal-skeleton {
  width: 300px !important;
  height: 300px !important;
}

.modal-skeleton:nth-of-type(1) {
  margin-right: 16px;
}

.modal-skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-thead > tr > th.bg-account {
  background-color: #0078b3 !important;
  color: white !important;
  text-align: left !important;
  padding: 10px 5px !important;
}

.ant-table-thead > tr > th.ant-table-row-expand-icon-cell {
  background-color: #0078b3 !important;
}

.algoooo > .ant-table-container table > thead > tr:first-child th:first-child {
  background-color: #0078b3 !important;
}

.ant-table-thead,
th.bg-blue {
  background-color: #0078b3 !important;
  color: white !important;
  text-align: left !important;
}
.size-icon {
  font-size: 30px;
}
.size-icon :hover {
  cursor: pointer;
}
.ant-table-thead > tr > th.bg-account,
.ant-table-tbody > tr > td.bg-account,
.ant-table tfoot > tr > th.bg-account,
.ant-table tfoot > tr > td.bg-account {
  position: relative;
  padding: 5px 5px;
  overflow-wrap: break-word;
}

.icon-coursor :hover {
  cursor: pointer;
}

.icon-hasntImage {
  color: #9b9b9b;
  opacity: 0.4;
}

.icon-hasImage {
  color: '#00499A';
}
.ant-row.manita :hover {
  cursor: pointer;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #ffffff;
  padding: 0;
}

/* .anticon-zoom-in {
  display: none;
}

.anticon-zoom-out {
  display: none;
} */

.anticon-rotate-left {
  display: none;
}
.anticon-rotate-right {
  display: none;
}

.ant-image-preview-operations {
  padding-top: 65px;
  padding-right: 28px;
  padding-bottom: 10px;
  background: none;
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.TableMassiveU .ant-table-thead > tr > th {
  color: #00499a;
}

.TableMassiveU .ant-table-thead > tr > th {
  color: #00499a;
}

.card-text-color {
  color: #e6001d;
}

.ant-card-body {
  padding: 10px;
}

.ant-table-thead,
th.title-masive {
  color: #00499a !important;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.TableMassiveU .ant-table-thead > tr > th {
  color: #00499a;
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.componentsHolder {
  width: 95%;
  background: white;
  margin: 1em auto;
  padding: 2em;
  box-sizing: border-box;
}

.buttonsFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: left !important;
  text-overflow: ellipsis;
}

td.ant-table-cell.firstColumn {
  width: 150px;
  text-align: left;
}

.ant-table-thead > tr > th {
  text-align: left;
}

span.ant-transfer-list-header-dropdown {
  display: none;
}

#ShoppingByFamilyTableConsult tfoot > tr > th,
.ant-table tfoot > tr > td {
  background: #004a96 !important;
  color: white;
}

#ShoppingByFamilyTableConsult td {
  padding: 5px 16px;
}

#ShoppingByFamilyTableConsult thead tr th {
  color: #004a96;
}

:root {
  --blue: #004a96;
}

.reset-btn {
  transform: translateY(2.5em);
  width: 50px;
  background: var(--blue) !important;
  color: white !important;
  font-weight: bold;
  border: none;
  outline: none;
}

.ant-table-thead > tr > th.data-column {
  color: var(--blue);
}

.modalBackgorund_wrapper__1xgNz {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
  overflow: auto;
}
.modalBackgorund_content__2jRbv {
  position: relative;
  top: 43px;
  left: 50%;
  width: 650px;
  min-height: 830px;

  transform: translateX(-50%);
}
.modalBackgorund_closeX__3kS6b {
  position: absolute;
  top: 30px;
  left: calc(50% + 324px);
  font-size: 24px;
  font-weight: 700;
  color: rgb(240, 240, 240);
  cursor: pointer;
  filter: drop-shadow(1px 1px 4px rgb(0, 0, 0, 1));
}

.ant-table-thead,
th.title-masive{
    color: #00499a !important;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
:root{
    --table-head-color: #0078B3;
    --table-cell-color: #707070;
    --label-color: #7AB0D6; 
}

.logsHolder {
  width: 90%;
  max-width: 1366px;
  margin: 0 auto;
}

.tableHolder {
  margin: 20px 0 0;
}

.selectHader {
  display: block;
}

.logs-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logs-header__side:nth-child(1) {
  margin: 0;
}

.logs-header__side:nth-child(1) .ant-select.ant-select-single.ant-select-show-arrow {
  width: 290px;
}

.logs-header__side.last-btn {
  margin: 0 0 0 1em;
}

.tableHolder .ant-table-thead .ant-table-cell,
.selectHader {
  color: var(--table-head-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}

.tableHolder .ant-table-filter-column-title {
  font-size: 0.8rem;
}

.selectHader {
  color: var(--label-color);
}

.tableHolder .ant-table-cell {
  color: var(--table-cell-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}

.tableHolder .ant-table-tbody .ant-table-cell {
  border-bottom: 1px solid var(--table-cell-color);
}

.tableHolder .ant-table-tbody > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
  box-sizing: border-box;
  word-break: break-word;
}

.tableHolder .ant-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
  transform: translateX(1em);
}

.tableHolder .ant-pagination-options {
  display: none;
}

.tableHolder .ant-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
  transform: translateX(3.5em);
}

@media screen and (min-width: 768px) {
  .logs-header__side:nth-child(1) {
    margin: 0 1em 0 0;
  }
}

.form-add-user-input input,
.form-add-user-input label {
  display: block;
}
.form-add-user-input label {
  margin: 0px;
  /* margin: 0px 0px 3px 5px; */
}
.form-add-user-input input {
  width: 100%;
}
.form-add-user-right {
  text-align: right;
}
#form-add-user-common {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-template-rows: auto;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-areas:
    'name name last_name last_name second_last_name second_last_name'
    'email email email leaderemail leaderemail leaderemail';
}

#name,
#last_name,
#second_last_name,
#email,
#leader-email {
  align-self: start;
}

#name {
  grid-area: name;
}
#surName {
  grid-area: last_name;
}
#lastName {
  grid-area: second_last_name;
}
#email {
  grid-area: email;
}
#emailLeader {
  grid-area: leaderemail;
}

#userBussinesEstructureSelect {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-areas:
    'region lob subregion territory'
    'zone country . .';
}

#regionsSelect,
#lobsSelect,
#subregionsSelect,
#countriesSelect,
#territoriesSelect,
#zonesSelect {
  align-self: center;
}

#regionsSelect {
  grid-area: region;
}

#lobsSelect {
  grid-area: lob;
}

#subregionsSelect {
  grid-area: subregion;
}

#countriesSelect {
  grid-area: country;
}

#territoriesSelect {
  grid-area: territory;
}

#zonesSelect {
  grid-area: zone;
}

#profileForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-areas: 'profile area . .';
}

#profileSelect,
#areaSelect {
  align-self: center;
}
#profileSelect {
  grid-area: profile;
}
#areaSelect {
  grid-area: area;
}

#clientForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-areas: 'clientSelect . . updateButton';
}
#clientSelect,
#updateButton {
  align-self: center;
}
#clientSelect {
  grid-area: clientSelect;
}

#updateButton {
  grid-area: updateButton;
}

/* input:disabled {
  background-color: #f5f5f5 !important;
} */

.notifications-page .show-all {
  display: none;
}



.notifications-page .notification-menu {
  margin: 2rem;
}

.notifications-page .notification-menu h2 {
  display: none;
}


.notifications-page .show-old {
  display: block;
}

 .show-old {
  display: none;
}

.color-button{
  color: #ff9600;
}
.leaflet-container {
  height: 350px !important;
  width: 95% !important;
  z-index: 0;
  /*  max-width: 500px;
  height: 300px;
   z-index: 0;
  position: absolute;  */
}

/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */

.hand-cursor {
  cursor: pointer !important;
}

.drawer-header {
  background-color: #00499a !important;
  color: white;
  font-weight: bolder;
  /* font-size: 10px; */
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.white-color {
  color: white;
}

.ant-collapse-header {
  color: #00499a !important;
  font-weight: bolder;
}

.ant-collapse-content-box > p,
.ant-collapse-content-box > .ant-form > p {
  color: #00499a !important;
  font-weight: 500;
  margin-bottom: 0px;
  padding-left: 5px;
}

.ant-collapse-content-box {
  background-color: #f2f2f2 !important;
}

.ant-drawer-body {
  padding: 0;
  background-color: #f2f2f2;
}

.ant-row.ant-form-item {
  margin-bottom: 5px;
  padding-left: 0px;
}

.sidebar-mc-btn {
  position: fixed;
  top: 4rem;
  right: 0;
}

.anticon-loading.anticon-spin {
  color: #00499a !important;
}

/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */


@media print {
    button,
    header,
    .position-sidebar,
    .ant-drawer-open,
    .ant-pagination {
      display: none !important;
    }
  
    .comparative-last-row td {
      border: none !important;
    }
    .children-wrapper {
      padding-left: 0px !important;
    }
  
    #root {
      width: 900px !important;
    }
  
   
  }
  
.total-row {
  text-align: left;
  background-color: #0078b3;
  color: white;
}

.total-number {
  color: white;
  text-align: center !important;
}

th.row-head {
  background-color: #f2f2f2 !important;
  text-align: left !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  font-weight: 500 !important;
}

th.row-head-color {
  background-color: #f2f2f2 !important;
}

td.row-head-color {
  height: 5px !important;
}

.custom-table th,
.custom-table td {
  padding-left: 0px !important;
  padding-right: 7px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow-wrap: break-word;
}

.custom-table .ant-result-image svg {
  transform: none !important;
}
.custom-table .ant-result-subtitle {
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  padding-top: 60px;
}

/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */

.hand-cursor {
  cursor: pointer !important;
}

.drawer-header {
  background-color: #00499a !important;
  color: white;
  font-weight: bolder;
  /* font-size: 10px; */
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.white-color {
  color: white;
}

.ant-collapse-header {
  color: #00499a !important;
  font-weight: bolder;
}

.ant-collapse-content-box > p,
.ant-collapse-content-box > .ant-form > p,
.title {
  color: #00499a !important;
  font-weight: 500;
  margin-bottom: 0px;
  padding-left: 5px;
}

.ant-collapse-content-box {
  background-color: #f2f2f2 !important;
}

.ant-drawer-body {
  padding: 0;
  background-color: #f2f2f2;
}

.ant-drawer-content-wrapper {
  margin-top: 50px;
  /* width: 285px; */
}

.ant-row.ant-form-item {
  margin-bottom: 5px;
  padding-left: 0px;
}

.sidebar-mc-btn {
  position: fixed;
  top: 4rem;
  right: 0;
}

.anticon-loading.anticon-spin {
  color: #00499a !important;
}

/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */

.modal-dash-Btn-c {
    display: flex;
    justify-content: center;
}

.modal-dash-Btn-c Button:first-child {
    margin-right: 1rem;
}

.excel-Btn {
    background-color: #0078B3;
    color: #F9F9F9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20%;
    height: 20%;
    border-radius: 5px;
}

.img-Btn:hover {
    background-color: #00499A;
    color: #F9F9F9;
}
.excel-Btn:hover {
    background-color: #0078B3;
    color: #F9F9F9;
}

.img-Btn:active {
    background-color: #00499A;
    color: #F9F9F9;
}
.excel-Btn:active {
    background-color: #0078B3;
    color: #F9F9F9;
}

.img-Btn {
    background-color: #00499A;
    color: #F9F9F9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20%;
    height: 20%;
    border-radius: 5px;
}

.img-Btn.mr {
    margin-right: 1rem;
}

.myTable th {
    background-color: #0078b3 !important;
    font-family: 'Montserrat';
    color: white !important;
}

.myClass td {
    font-family: 'Montserrat';
    font-size: 14px;
}

.miHeader th {
    background-color: #00499a !important;
    font-family: 'Montserrat';
    color: white !important;
}

.width {
    width: 25%;
    height: 30%;
}

.width2 {
    width: 80%;
}

.button-1 {
    min-width: 15%;
    background-color: #00499a;
    color: white;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 14px;
}

.button-1:hover {
    background-color: #00499a;
    color: white;
}

.margin {
    float: right;
}

.enproceso {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #00499a;
}

.flex-centered {
    position: absolute;
    top: 2px;
    left: 0;
    bottom: 2px;
    height: 95% !important;
    width: .4rem !important;
}

.envalidación {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #b55cff;
}

.ensegundavalidación {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #ffb54c;
}

.aprobado {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #00c14f;
}

.rechazadoparcialmente {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #f16800;
}

.rechazado {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #e6001d;
}

.table {
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 426px;
    position: relative;
}

.th {
    font-family: 'Montserrat';
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: left;
    background-color: #00499a !important;
    color: white;
    text-align: center;
}

.th2 {
    font-family: 'Montserrat';
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    background-color: #f9f9f9 !important;
    color: black;
}

.th3 {
    font-family: 'Montserrat';
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    background-color: #0078b3 !important;
    color: white;
}

.body {
    background-color: #ffffff;
    font-family: 'Montserrat';
    font-size: 14px;
}

.body2 {
    background-color: #ffffff;
    font-family: 'Montserrat';
    font-size: 14px;
    text-align: left;
}

.body3 {
    background-color: #f9f9f9;
    font-family: 'Montserrat';
    font-size: 14px;
    text-align: center;
}

.right {
    margin-left: 272px !important;
    width: 148px;
}

.sales-challenge-mc {
    position: relative;
}

.button-2 {
    width: 9%;
    background-color: #00499a;
    color: white;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 14px;
    margin-bottom: 10px;
}

.excel-Btn {
    background-color: #0078b3;
    color: #f9f9f9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20%;
    height: 20%;
    border-radius: 5px;
}

.img-Btn {
    background-color: #00499a;
    color: #f9f9f9;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center !important;
    width: 20% !important;
    border-radius: 5px;
}

.center {
    text-align: center;
}

.center-title {
    text-align: center;
    color: #00499a;
    font-family: 'Montserrat';
}

.center-title-Ic {
    float: left;
    margin-left: 1rem;
    font-size: 1.3rem;
}

.second-mod {
    /* margin-left: 1rem; */
    margin-top: 4rem;
    width: 425px;
    text-align: right;
    justify-content: right;
}

.ant-table-wrapper {
    min-height: 0 !important;
}


.result-svg .ant-result-title {
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
    padding-top: 60px;
}


.agreement-table .ant-result-image svg {
    transform: none !important;
}

.danger-color {
    color: #e6001d !important;
}


.salesC-sales-table {
    width: 100%;
}

.salesC-sales-table tr,
.table tr {
    background-color: white;
    border-bottom: 1px solid #707070;
}

.salesC-sales-table tr:first-child,
.table tr:first-child {
    border-bottom: 0;
}

.salesC-sales-table tr:last-child,
.table tr:last-child {
    border-bottom: 0;
}

.salesC-sales-table th,
tr.total,
.status-total {
    background-color: #0078b3 !important;
    color: white;
    text-align: center;
    font-family: 'Montserrat';
    padding: 10px;
    font-weight: 400;
}

.status-total td {
    background-color: #0078b3 !important;
}

.status-total td:last-child {
    border-left: 1px solid white;
}

.salesC-sales-table th {
    padding: 10px !important;
}

.salesC-sales-table td {
    padding: 10px!important;
    text-align: center;
    font-family: Montserrat;
}

.square {
    display: flex;
    align-items: center;
}

.square div {
    height: 1rem;
    width: 2rem;
    margin-right: 5px;
    margin-left: 5px;
}

.square div:first-child {
    margin-left: 0;
}

.square div.green,
td.green {
    border: 4px solid #00c14f;
    background-color: white !important;
    color: #707070;
}

.square div.yellow,
td.yellow {
    border: 4px solid #ffb54c;
    background-color: white !important;
    color: #707070;
}

.square div.blue,
td.blue {
    border: 4px solid #00499a;
    background-color: white !important;
    color: #707070;
}

.square div.red,
td.red {
    border: 4px solid #e6001d;
    background-color: white !important;
    color: #707070;
}

.result-svg .ant-result-image svg {
    transform: none !important;
}

.result-svg .ant-result-title {
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
    padding-top: 60px;
}

/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */

.hand-cursor {
  cursor: pointer !important;
}

.drawer-header {
  background-color: #00499a !important;
  color: white;
  font-weight: bolder;
  /* font-size: 10px; */
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.white-color {
  color: white;
}

.ant-collapse {
  max-height: calc(100vh - 106px);
  overflow-y: auto;
}

.ant-collapse-header {
  color: #00499a !important;
  font-weight: bolder;
}

.ant-collapse-content-box > p,
.ant-collapse-content-box > .ant-form > p {
  color: #00499a !important;
  font-weight: 500;
  margin-bottom: 0px;
  padding-left: 5px;
}

.ant-collapse-content-box {
  background-color: #f2f2f2 !important;
}

.ant-drawer-body {
  padding: 0;
  background-color: #f2f2f2;
}

.ant-row.ant-form-item {
  margin-bottom: 5px;
  padding-left: 0px;
}

.sidebar-mc-btn {
  position: fixed;
  top: 4rem;
  right: 0;
}

.anticon-loading.anticon-spin {
  color: #00499a !important;
}
#zoneMessage {
  color: #e6001d !important;
}
/* ************************ */
/* Ant Desing Drawer styles */
/* ************************ */

.periodicity-container {
  margin-top: 15px;
}

.comparative-pair-row,
.comparative-odd-row,
.comparative-last-row {
  text-align: center;

  color: #707070;
}

.comparative-pair-row .ant-table-cell,
.comparative-odd-row .ant-table-cell,
.comparative-last-row .ant-table-cell {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  border: none;
  border-bottom: 1px solid #ebf5fc;
}

.comparative-odd-row {
  background-color: #f9f9f9;
}
.comparative-last-row {
  background-color: #0078b3 !important;
  color: white;
}
/* .ant-table-tbody > tr.ant-table-row:hover > td, */
.ant-table-tbody > tr.comparative-last-row:hover > td {
  background-color: #0078b3 !important;
}
.comparative-last-row > td {
  border-right: 1px solid #1d87bc !important;
}
th.periodicity-cell {
  color: white !important;
  background-color: #0078b3 !important;
  text-align: center !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
}

.validation-type__wrapper {
  margin: 3em 0 0 0;
  display: grid;
  grid-template-columns: 180px 60% 250px;
  grid-gap: 1em;
}

.validation-type__wrapper h4 {
  display: inline-block;
  color: #0078b3;
  font-weight: 1rem;
  margin-right: 1em;
}

.validation-type__wrapper .ant-radio-group {
  margin: 0 0 0 0.5em;
  display: flex;
  justify-content: space-around;
}

.validation-type__wrapper .ant-radio-group .ant-radio-wrapper {
  display: inline-block;
}

.validators__holder {
  width: 61.8%;
  min-width: 500px;
  margin: 0 0 0 2.75em;

  justify-content: space-between;
}

.validatorItem h5 {
  color: #0078b3;
  font-size: 1rem;
  margin: 0 0 0.5em;
}

.validatorItem button,
.validatorItem button:hover {
  background: transparent;
  color: #0078b3;
  border: none;
  outline: none;
}

.validatorItem__wrap {
  margin: 0 0 0.75em 0;

  display: flex;
  align-items: end;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.validatorItem__wrap .ant-select {
  width: 100%;
}

.validatorItem__wrap p {
  color: var(--blue-3) !important;
  font-size: 0.8rem;
  margin: 0 0 0.25em 0;
}
.validators-list-wrapper:nth-child(2n + 1) {
  background-color: rgba(0, 120, 179, 0.035);
}
.ant-btn.reportBtn,
.ant-btn.reportBtn:hover .ant-btn.reportBtn:focus .ant-btn.reportBtn:active {
  background: #0078b3;
  color: white;
  border: none;
  outline: none;
  font-weight: bold;
}

.validations__filters {
  width: 95%;
  /* max-width: 1248px; */
  margin: 0 auto;
}

.reportModal__wrapper {
  padding: 1em;
  box-sizing: border-box;
}

.reportModal__wrapper h4 {
  color: #0078b3;
  margin: 0 0 1em 0;
  text-align: left;
  font-size: 1rem;
}

.reportModal__wrapper__buttons {
  width: 90%;
  margin: 1em auto;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.reportModal__wrapper__buttons button,
.reportModal__wrapper__buttons button:hover,
.reportModal__wrapper__buttons button:active {
  width: 180px;
  height: 50px;
  padding: 1em;
  box-sizing: border-box;
  background: #0078b3;
  color: white;
  border: none;
  outline: none;
}

.validators-list-wrapper {
  padding: 0.5em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-width: 0;
  flex: 5 1;
}
.already-added-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  margin-top: 25px;

  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 300px;
}
.validators-delete {
  color: #e6001d;
  font-size: 1.1rem;
  transform: translate(0.25em, 0.17em);
}
.validators-list-wrapper .ant-select {
  width: 100%;
}

.validator-name-and-position-label {
  color: #0078b3;
  font-size: 0.8rem;
  white-space: nowrap;
}
.validator-scope-wrapper {
  font-size: 0.9em;
  white-space: normal;
}

.validator-scope-element {
  display: inline-block;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 120, 179, 0.25);
  color: rgb(0, 120, 179);
  margin: 4px;
  margin-top: 6px;
  box-shadow: 2px 2px 5px rgba(0, 120, 179, 0.25);
}
.modal-title {
  text-align: left;
  color: #0078b3;
}

.validator-add-selecter {
  width: 400px;
}

.custom-spin svg {
  fill: #fff !important;
  margin-right: 10px;
}

.add-container {
  display: flex;
  align-items: baseline;
}

.add-container p {
  margin-left: 15px;
  margin-bottom: 0;
}

.header-modal {
  display: flex;

  align-items: baseline;
  color: #003d81;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.header-modal p {
  margin-left: 2rem;
  margin-bottom: 0;
}

.form-item {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.form-item p {
  color: #7ab0d6;
}

.form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-space-item {
  width: 100%;
}

.modal-add-product .ant-form-item {
  margin-bottom: 5px;
  min-width: 350px;
  max-width: 350px;
}

span.anticon.anticon-minus-circle.remove-element {
  right: 0;
  top: 15px;
  color: red;
}

.footer-modal {
  margin-top: 1rem;
}

.body-modal {
  margin-top: 1rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 2rem;
}

.ant-table-content {
  overflow: auto;
}
.bcard-agreements {
  /* background: var(--main-theme-color) !important; */
  color: #fff;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 1rem;
}
.bcard-agreements .ant-card-head-title {
  padding: 4px;
}
.bcard-agreements .ant-card-head-title h3 {
  font-size: 0.99em !important;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  margin-bottom: 0;
  font-weight: 300;
}

.bcard-agreements .ant-card-head-title h3:after {
  display: none;
}

.bcard-agreements .ant-card-head {
  padding: 0 5px !important;
  font-size: 1em !important;
  min-height: 38px !important;
  border-bottom: none;
}

.bcard-agreements .ant-card-body h2 {
  color: var(--gray-dark) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.type-value .ant-card-body {
  padding-bottom: 0px;
}

.bcard-agreements .ant-card-body {
  border-radius: 0px 0px 8px 8px;
}
.ant-table-container {
  border-radius: 8px;
}
.bcard-agreements {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.bg-quarterly-card .ant-card-head-title h3 {
  /* background: var(--main-theme-color) !important; */
  color: #fff;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 1rem;
}

.bg-quarterly-card-product .ant-card-body {
  min-height: 100px !important;
}

.btable-agreements thead th {
  font-weight: 700;
}

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: -webkit-fill-available;
}

[class~='ant-row'] {
  padding: 4px;
}

.data-table .ant-table-pagination.ant-pagination {
  background: #fff;
  margin: 2px 5px;
  padding: 5px;
  text-align: right;
}

.data-table .ant-spin-container {
  background: #fff;
}

.add-container {
  display: flex;
  align-items: baseline;
}

.add-container p {
  margin-left: 15px;
  margin-bottom: 0;
}

.header-modal {
  display: flex;

  align-items: baseline;
  color: #003d81;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.header-modal p {
  margin-left: 2rem;
  margin-bottom: 0;
}

.form-item {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.form-item p {
  color: #7ab0d6;
}

.form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-space-item {
  width: 100%;
}

.modal-add-product .ant-form-item {
  margin-bottom: 5px;
  min-width: 350px;
  max-width: 350px;
}

span.anticon.anticon-minus-circle.remove-element {
  right: 0;
  top: 15px;
  color: red;
}

.footer-modal {
  margin-top: 1rem;
}

.bg {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.bproduct-info {
  margin-bottom: 1rem;
  overflow: hidden;
}

.bproduct-info .ant-form {
  min-height: 308px;
}

.bproduct-info .ant-card-body {
  padding: 2px;
}

.bproduct-info .ant-card-body .ant-form-item {
  margin-bottom: 5px;
}

.bproduct-info h3 {
  color: var(--main-theme-color);
  margin-bottom: 0;
  font-size: 1em;
  text-align: left;
  padding: 6px 8px;
}

.bproduct-info .ant-skeleton-element {
  margin-bottom: 30px;
}

.bproduct-info .ant-col {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.btable-agreements .ant-table-cell {
  font-size: 0.8em !important;
  color: var(--gray-dark);
  font-weight: 600;
  padding: 2px;
}
.btable-agreements .ant-table-column-sorters {
  padding: 5px !important;
}
.btable-agreements .ant-table-content {
  min-height: 325px;
}

.btable-agreements {
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition-property: box-shadow, border-color;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
}

.btable-agreements tbody tr td:first-child {
  padding-left: 20px !important;
}

.ant-table-empty .ant-result {
  padding: 17px 32px;
}

.ant-result-image {
  height: 211px;
}
.ant-result-image svg {
  transform: scale(0.7);
}

.battery-info-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.battery-info-container h4 {
  font-size: 0.9rem;
  padding-top: 10px;
}

.battery-indicator {
  animation: fill 1s ease-in-out 1;
}

.battery-indicator .bar {
  transition: 0.5s ease-in;
}

.battery-indicator .bar path {
  fill: #fff;
  animation: bounce linear 400ms;
  transition: 2s all;
  margin: auto;
}

@keyframes bounce {
  0% {
    transform: scale(0);
    fill: currentColor;
  }

  80% {
    transform: scale(1.1);
    fill: currentColor;
  }

  100% {
    transform: scale(1);
    fill: currentColor;
  }
}

:root {
  --info-color: #0078b3;
}
.bcard .ant-card-body {
  background: transparent;
  cursor: auto;
  cursor: initial;
}

.bcard.ant-card-hoverable {
  cursor: default;
}

.bcard .ant-card-body {
  background: #fff;
  color: var(--gray-dark);
  font-weight: 600;
}

.bcard-agreements {
  min-width: 190px;
}

.bcard .ant-card-head {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px 8px 0px 0px;
}

.bcard-default-header .ant-card-head {
  background: var(--main-theme-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.bcard-info-header .ant-card-head {
  background: #0078b3 !important;
  background: var(--info-color) !important;
}

.bcard-initial-header .ant-card-head {
  background: #fff !important;
  color: #000000d9;
}

.bcard-right-column-header .ant-card-head {
  background-color: var(--blue-3) !important;
  color: #fff;
}

h3:after {
  border: none;
}

/* .bcard .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.bcard .ant-skeleton {
  margin-top: 2rem !important;
}

.bcard .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-bottom: 0.5em !important;
  margin-top: 0 !important;
} */

.basf-switch-control {
  display: inline-block;
  margin: 0 1rem;
}

.basf-switch-control .ant-switch {
  background: var(--blue-2);
}
.basf-switch-control .ant-switch-handle::before {
  background: var(--blue-4);
}

.first-row-agreements .ant-col {
  /* margin-bottom: 1rem; */
}
.first-row-agreements .ant-card-body {
  min-height: 125px !important;
}

.first-row-agreements .bcard {
  width: 98%;
  margin: 0 auto;
}
.first-row-agreements .ant-card {
  background: transparent !important;
  border-radius: 8px;
}

.first-row-agreements .bcard .ant-card-head-title {
  padding: 9px 0px;
}

.first-row-agreements .bcard .ant-card-head-title h3 {
  color: #fff !important;
  font-size: 0.8rem !important;
  text-align: center !important;
}
.first-row-agreements .ant-card-head-title {
  text-align: center !important;
  min-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.first-row-agreements .ant-card-head-title h3 {
  font-size: 0.99em !important;
  color: #fff;
  text-align: left;

  padding-top: 4px;
  margin-bottom: 0;
  font-weight: 300;
  width: 100%;
}
.first-row-agreements .bquarty-sales .ant-card-body {
  width: 100%;
  padding: 0;
}
.first-row-agreements .btable-agreements .ant-table-content {
  min-height: auto;
}
.first-row-agreements .ant-card-body {
  min-height: 100px;
  color: var(--gray-dark);
}
.first-row-agreements .ant-table-content tr td {
  font-size: 0.7rem;
  padding: 2px !important;
}

.first-row-agreements .bcard {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.first-row-agreements .card-total-sale {
  font-size: 1.2rem;
}
.first-row-agreements .card-total-sale .ant-card-head-title h3 {
  margin-bottom: 0;
}

.first-row-agreements .card-total-sale .ant-card-body {
  padding: 0;
}
.first-row-agreements .card-total-sale .ant-card-body h4 {
  padding-top: 39px !important;
}

.first-row-agreements .card-type-value .ant-card-body {
  padding: 5px;
  padding-top: 37px;
  font-size: 0.7rem;
  padding-bottom: 10px;
}

.first-row-agreements .card-type-value .ant-card-body .basf-switch-control {
  margin: 0 0.25rem;
}
.first-row-agreements .card-battery .ant-card-body {
  padding: 0px;
}

.first-row-agreements h2,
.first-row-agreements h3 {
  color: var(--gray-dark);
}

.first-row-agreements .bquarty-sales .ant-table-content {
  min-height: 100px;
}

.first-row-agreements .bquarty-sales .ant-table-small .ant-table-thead > tr > th {
  background: var(--blue-1);
}

.first-row-agreements .bquarty-sales table tr:first-child td {
  border-bottom: none;
}

.first-row-agreements .bquarty-sales table td {
  font-size: 0.7rem !important;
  padding: 0px !important;
  line-height: 40px;
}

.first-row-agreements .bquarty-sales .ant-table-thead > tr > th {
  border: 1px solid #fff;
}

.first-row-agreements .bquarty-sales .ant-table {
  background: transparent;
}

.first-row-agreements .ant-card-head-wrapper {
  display: -webkit-inline-box;
}

.first-row-agreements .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 85%;
}

.bquarty-sales .bg {
  box-shadow: none !important;
}

.edit-table_container-table__2Ccsn table {
  border-collapse: collapse;
}

.edit-table_container-table__2Ccsn table thead tr th {
  background-color: white !important;
  color: var(--blue-4) !important;
  text-align: center;
  width: calc(1fr);
  padding: 0 !important;
}
.edit-table_container-table__2Ccsn table thead tr th:last-child {
  border: none;
  background-color: var(--blue-4) !important;
  color: white !important;
}
.edit-table_container-table__2Ccsn table tbody tr:first-child {
  padding: 0 !important;
}
/* .container-table table tbody tr:first-child td:first-child {
  background-color: var(--blue-2) !important;
}

.container-table table tbody tr:first-child td {
  background-color: var(--blue-2) !important;
} */

.edit-table_container-table__2Ccsn table tbody tr:first-child td:last-child {
  color: white !important;
}

.edit-table_container-table__2Ccsn table tbody tr:last-child td:last-child {
  color: white !important;
  background-color: var(--blue-4) !important;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
}

.edit-table_container-table__2Ccsn table tbody tr td:first-child {
  width: 90px;
  font-size: 0.78rem;
  padding: 0 !important;
  padding-left: 5px !important;
  font-weight: 300;
}
.edit-table_container-table__2Ccsn th.edit-table_title-month__23W0u {
  font-size: 0.7rem;
}

.editable th.cell-month,
.editable td.cell-month {
  padding: 0px !important;
  border-bottom: 1px solid #e4e4e4;
}
.editable th.cell-month {
  min-width: 60px;
  border: none;
}

.editable td.cell-month {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
}

th.cell-total {
  min-width: 85px;
  width: 85px;
}
td.cell-total {
  font-size: 0.7rem !important;
  font-weight: 500;
  padding: 5px !important;
}

.cell-month input {
  margin: 0;
  border-radius: none !important;
}

.cell-month .ant-form-item {
  margin: 0;
  padding: 0;
}
.cell-month button {
  height: 100%;
  min-height: 24px;
}

.cell-month input {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  min-height: 24px;
  height: 100%;
  background: transparent;
  vertical-align: bottom;
  padding-right: 2px;
  text-align: right;
  width: 100%;
}

.input-error {
  border: 2px solid red;
}

.div-value {
  padding-right: 2px;
  padding-bottom: 5px;
  text-align: right;
  min-width: 60px;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}
.editable tr td {
  border-color: 1px solid #f0f0f0;
  vertical-align: bottom;
}
.editable-input {
  background: transparent;
  width: 100%;
}
.container-editable .ant-input-number-handler-wrap {
  display: none;
}

.editable td.cell-month > .container-editable {
  background: var(--blue-2);
}
.cell-editable {
  background: var(--blue-2);
}

.editable tr td.cell-month:nth-child(even):not(.cell-editable) {
  background: #fafafa 0% 0% no-repeat padding-box;
}
.editable .ant-table-cell-fix-right {
  background: var(--blue-4);
  border-color: var(-blue-3);
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month {
  color: #707070;
}

td.ant-table-cell.cell-total,
.editable .ant-table-tbody > tr.ant-table-row:hover td.cell-total {
  background: var(--blue-4);
  color: #fff;
  text-align: right;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month:nth-child(even) {
  background: #fafafa 0% 0% no-repeat padding-box;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-editable {
  background: var(--blue-2) !important;
}

td div.container-editable {
  color: #707070;
  min-width: 65px;
  overflow: hidden;
  font-size: 0.7rem;
  text-align: right;
}

td div.container-editable .ant-form-item-control-input {
  min-height: 24px;
}

.editable td {
  font-size: 0.7rem;
  text-align: right;
}

.editable .cell-title {
  font-size: 0.8rem;
  text-align: left;
  height: 35px;
}

.editable .cell-current-period {
  font-weight: 500 !important;
}

.ant-table-empty table tbody tr:last-child td:last-child {
  background-color: transparent !important;
  background-color: initial !important;
}

.button-full-table {
  top: 2px;
  z-index: 1;
  background: transparent !important;
  border: none;
  padding: 0px 8px;
  margin: 0px 5px;
  text-align: center;
  color: #707070 !important;
  border-radius: 0px;
}
.button-full-table svg {
  stroke: black;
}

.container-full-table .ant-modal-content {
  background: transparent !important;
}
.container-full-table .ant-modal-body {
  padding: 0px;
}
.container-full-table .ant-modal-close {
  color: #fff;
  border-radius: 4px;
  padding: 0px;
  font-size: 1rem;
}
.container-full-table .ant-modal-close {
  top: -56px;
}
.container-full-table .ant-modal-close-x {
  font-size: 1.5rem;
}

.container-full-table .ant-modal-close .anticon {
  padding: 10px;
  margin-left: 15px;
}

.container-full-table td div.container-editable {
  width: 78px;
}

.container-full-table .ant-modal-footer {
  display: none;
}

.editable-challenge {
  min-height: 320px;
  margin-top: 0rem;
}

.editable-challenge .ant-table-empty {
  margin-top: 0 !important;
}

.editable-has-data {
  margin-top: -2rem;
}

.editable-challenge-consult .ant-result-image {
  height: 224px;
}

@media (min-width: 1024px) {
  td div.container-editable {
    width: 68px;
  }
}

@media (min-width: 1368px) {
  td div.container-editable {
    width: 100px;
    font-size: 0.9rem;
  }

  .editable td.cell-month,
  th.cell-total {
    font-size: 0.9rem !important;
  }

  .editable tr td.cell-title {
    font-size: 0.8rem !important;
  }
  th.cell-total {
    min-width: 90px;
    width: 90px;
  }
  .editable-challenge .ant-result-image {
    height: 231px;
  }

  .editable-challenge-consult .ant-result-image {
    height: 244px;
  }
}

.second-row-agreements {
  margin-top: -1rem;
}
.second-row-agreements .ant-table-wrapper {
  width: 100%;
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.second-row-agreements .ant-table,
.second-row-agreements .ant-table-content {
  border-radius: 8px;
}
.second-row-agreements .editable-has-data {
  margin-top: 0;
}

.second-row-agreements .button-full-table {
  top: 32px;
}
.second-row-agreements .ant-table-empty {
  margin-top: 2rem;
}

.history-modal .ant-modal-title {
  color: var(--blue-4);
}

.history-modal .ant-modal-footer button:first-of-type {
  display: none;
}
.history-modal .ant-modal-footer {
  border-top: none;
}
.history-modal .ant-modal-title::after {
  content: ' ';
  width: 70%;
  height: 2px;
  display: block;
  border-bottom: 2px solid;
  border-color: var(--blue-3);
  left: 28%;
  position: absolute;
  margin-top: -4px;
}

.history-modal .ant-modal-body {
  padding: 0px;
}

.history-modal .ant-result-title {
  margin-top: 3rem;
}

.history-modal .thead {
  display: none;
}

.history-modal .ant-modal-close-x {
  display: none;
}
.history-modal .btable-agreements .ant-table-content {
  min-height: 150px !important;
}

.container-comments {
  width: 200px;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
}

.container-comments .ant-tag {
  white-space: break-spaces;
}

.ant-list {
  width: 100%;
  text-align: center;
}

#create-trade-agreement {
  background: #fff;
  padding: 20px;
  margin: 20px;
}

#create-trade-agreement h3:after {
  border-bottom: 2px solid;
  width: 75%;
  margin-left: 20px;
  margin-top: 15px;
  position: absolute;
  right: 0;
}

.descargar-reporte__boton .ant-btn-loading-icon .anticon-loading {
  color: white !important;
}

.deleteModal {
  width: 80% !important;
  max-width: 600px;
}

.ant-table-content {
  overflow: auto;
}
.bcard-sales {
  /* background: var(--main-theme-color) !important; */
  color: #fff;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 1rem;
}
.bcard-sales .ant-card-head-title {
  padding: 4px;
}
.bcard-sales .ant-card-head-title h3 {
  font-size: 0.99em !important;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  margin-bottom: 0;
  font-weight: 300;
}

.bcard-sales .ant-card-head-title h3:after {
  display: none;
}

.bcard-sales .ant-card-head {
  padding: 0 5px !important;
  font-size: 1em !important;
  min-height: 38px !important;
  border-bottom: none;
}

.bcard-sales .ant-card-body h2 {
  color: var(--gray-dark) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.type-value .ant-card-body {
  padding-bottom: 0px;
}

.bcard-sales .ant-card-body {
  border-radius: 0px 0px 8px 8px;
}
.ant-table-container {
  border-radius: 8px;
}
.bcard-sales {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.btable-sales thead th {
  font-weight: 700;
}

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: -webkit-fill-available;
}

[class~='ant-row'] {
  padding: 4px;
}

:root {
  --info-color: #0078b3;
}
.bcard .ant-card-body {
  background: transparent;
  cursor: auto;
  cursor: initial;
}

.bcard.ant-card-hoverable {
  cursor: default;
}

.bcard .ant-card-body {
  background: #fff;
  color: var(--gray-dark);
  font-weight: 600;
}

.bcard-agreements {
  min-width: 190px;
}

.bcard .ant-card-head {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px 8px 0px 0px;
}

.bcard-default-header .ant-card-head {
  background: var(--main-theme-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.bcard-info-header .ant-card-head {
  background: #0078b3 !important;
  background: var(--info-color) !important;
}

.bcard-initial-header .ant-card-head {
  background: #fff !important;
  color: #000000d9;
}

.bcard-right-column-header .ant-card-head {
  background-color: var(--blue-3) !important;
  color: #fff;
}

h3:after {
  border: none;
}

/* .bcard .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.bcard .ant-skeleton {
  margin-top: 2rem !important;
}

.bcard .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-bottom: 0.5em !important;
  margin-top: 0 !important;
} */

.bg-single-title .ant-card-body {
  background: var(--main-theme-color) !important;
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 5px;
}
.bg-single-title .ant-card-body {
  display: none;
}

.white-loader {
  color: #fff !important;
}

.bg-single-title .ant-card-head {
  border-radius: 8px;
}

.stadistics-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin: 0 5px;
}

.stadistics-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 14px;
  width: 100%;
}

.stadistics-value {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-3);
  font-weight: bold;
  color: white;
  font-size: 16px;
  line-height: 20px;
  padding: 4px 7px;
  width: 100%;
}

.progress-container-animate div {
  transition: 0.6s ease-in;
}

.button-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  animation: down 0.3s linear 1;
}

.transition {
  transition: ease-in 0.4s all;
}

@keyframes down {
  0% {
    bottom: 25px;
    opacity: 1;
  }
  50% {
    bottom: 15px;
    opacity: 1;
  }
  75% {
    bottom: 5px;
    opacity: 1;
  }

  100% {
    bottom: 0px;
    opacity: 1;
  }
}

#create-trade-agreement {
  background: #fff;
  padding: 20px;
  margin: 20px;
}

#create-trade-agreement h3:after {
  border-bottom: 2px solid;
  width: 75%;
  margin-left: 20px;
  margin-top: 15px;
  position: absolute;
  right: 0;
}

.basf-switch-control {
  display: inline-block;
  margin: 0 1rem;
}

.basf-switch-control .ant-switch {
  background: var(--blue-2);
}
.basf-switch-control .ant-switch-handle::before {
  background: var(--blue-4);
}

.custom-spin svg {
  fill: #fff !important;
  margin-right: 10px;
}

.bg {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.bproduct-info {
  margin-bottom: 1rem;
}

.bproduct-info .ant-form {
  min-height: 308px;
}

.bg-agreements.bproduct-info .ant-form {
  min-height: 235px;
}

.bproduct-info .ant-card-body {
  padding: 2px;
}

.bproduct-info .ant-card-body .ant-form-item {
  margin-bottom: 5px;
}

.bproduct-info h3 {
  color: var(--main-theme-color);
  margin-bottom: 0;
  font-size: 1em;
  text-align: left;
  padding: 6px 8px;
}

.bproduct-info .ant-skeleton-element {
  margin-bottom: 30px;
}

.bproduct-info .ant-col {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.deleteProduct__btn,
.deleteProduct__btn:hover,
.deleteProduct__btn:active {
  border: none;
  box-shadow: none;
  outline: none;
}

.ant-table-content {
  overflow: auto;
}
.bcard-agreements {
  /* background: var(--main-theme-color) !important; */
  color: #fff;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 1rem;
}
.bcard-agreements .ant-card-head-title {
  padding: 4px;
}
.bcard-agreements .ant-card-head-title h3 {
  font-size: 0.99em !important;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  margin-bottom: 0;
  font-weight: 300;
}

.bcard-agreements .ant-card-head-title h3:after {
  display: none;
}

.bcard-agreements .ant-card-head {
  padding: 0 5px !important;
  font-size: 1em !important;
  min-height: 38px !important;
  border-bottom: none;
}

.bcard-agreements .ant-card-body h2 {
  color: var(--gray-dark) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.type-value .ant-card-body {
  padding-bottom: 0px;
}

.bcard-agreements .ant-card-body {
  border-radius: 0px 0px 8px 8px;
}
.ant-table-container {
  border-radius: 8px;
}
.bcard-agreements {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.bg-quarterly-card .ant-card-head-title h3 {
  /* background: var(--main-theme-color) !important; */
  color: #fff;
  font-size: 1em;
  width: 100%;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 1rem;
}

.bg-quarterly-card-product .ant-card-body {
  min-height: 100px !important;
}

.btable-agreements thead th {
  font-weight: 700;
}

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: -webkit-fill-available;
}

[class~='ant-row'] {
  padding: 4px;
}

.data-table .ant-table-pagination.ant-pagination {
  background: #fff;
  margin: 2px 5px;
  padding: 5px;
  text-align: right;
}

.data-table .ant-spin-container {
  background: #fff;
}

:root {
  --info-color: #0078b3;
}
.bcard .ant-card-body {
  background: transparent;
  cursor: auto;
  cursor: initial;
}

.bcard.ant-card-hoverable {
  cursor: default;
}

.bcard .ant-card-body {
  background: #fff;
  color: var(--gray-dark);
  font-weight: 600;
}

.bcard-agreements {
  min-width: 190px;
}

.bcard .ant-card-head {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px 8px 0px 0px;
}

.bcard-default-header .ant-card-head {
  background: var(--main-theme-color) !important;
  border-radius: 8px 8px 0px 0px;
}

.bcard-info-header .ant-card-head {
  background: #0078b3 !important;
  background: var(--info-color) !important;
}

.bcard-initial-header .ant-card-head {
  background: #fff !important;
  color: #000000d9;
}

.bcard-right-column-header .ant-card-head {
  background-color: var(--blue-3) !important;
  color: #fff;
}

h3:after {
  border: none;
}

/* .bcard .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
}

.bcard .ant-skeleton {
  margin-top: 2rem !important;
}

.bcard .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-bottom: 0.5em !important;
  margin-top: 0 !important;
} */

.btable-agreements .ant-table-cell {
  font-size: 0.8em !important;
  color: var(--gray-dark);
  font-weight: 600;
  padding: 2px;
}
.btable-agreements .ant-table-column-sorters {
  padding: 5px !important;
}
.btable-agreements .ant-table-content {
  min-height: 325px;
}

.btable-agreements {
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
  transition-property: box-shadow, border-color;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;
}

.btable-agreements tbody tr td:first-child {
  padding-left: 20px !important;
}

.ant-table-empty .ant-result {
  padding: 17px 32px;
}

.ant-result-image {
  height: 211px;
}
.ant-result-image svg {
  transform: scale(0.7);
}

.first-row-agreements .ant-col {
  /* margin-bottom: 1rem; */
}
.first-row-agreements .ant-card-body {
  min-height: 125px !important;
}

.first-row-agreements .bcard {
  width: 98%;
  margin: 0 auto;
}
.first-row-agreements .ant-card {
  background: transparent !important;
  border-radius: 8px;
}

.first-row-agreements .bcard .ant-card-head-title {
  padding: 9px 0px;
}

.first-row-agreements .bcard .ant-card-head-title h3 {
  color: #fff !important;
  font-size: 0.8rem !important;
  text-align: center !important;
}
.first-row-agreements .ant-card-head-title {
  text-align: center !important;
  min-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.first-row-agreements .ant-card-head-title h3 {
  font-size: 0.99em !important;
  color: #fff;
  text-align: left;

  padding-top: 4px;
  margin-bottom: 0;
  font-weight: 300;
  width: 100%;
}
.first-row-agreements .bquarty-sales .ant-card-body {
  width: 100%;
  padding: 0;
}
.first-row-agreements .btable-agreements .ant-table-content {
  min-height: auto;
}
.first-row-agreements .ant-card-body {
  min-height: 100px;
  color: var(--gray-dark);
}
.first-row-agreements .ant-table-content tr td {
  font-size: 0.7rem;
  padding: 2px !important;
}

.first-row-agreements .bcard {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.first-row-agreements .card-total-sale {
  font-size: 1.2rem;
}
.first-row-agreements .card-total-sale .ant-card-head-title h3 {
  margin-bottom: 0;
}

.first-row-agreements .card-total-sale .ant-card-body {
  padding: 0;
}
.first-row-agreements .card-total-sale .ant-card-body h4 {
  padding-top: 39px !important;
}

.first-row-agreements .card-type-value .ant-card-body {
  padding: 5px;
  padding-top: 37px;
  font-size: 0.7rem;
  padding-bottom: 10px;
}

.first-row-agreements .card-type-value .ant-card-body .basf-switch-control {
  margin: 0 0.25rem;
}
.first-row-agreements .card-battery .ant-card-body {
  padding: 0px;
}

.first-row-agreements h2,
.first-row-agreements h3 {
  color: var(--gray-dark);
}

.first-row-agreements .bquarty-sales .ant-table-content {
  min-height: 100px;
}

.first-row-agreements .bquarty-sales .ant-table-small .ant-table-thead > tr > th {
  background: var(--blue-1);
}

.first-row-agreements .bquarty-sales table tr:first-child td {
  border-bottom: none;
}

.first-row-agreements .bquarty-sales table td {
  font-size: 0.7rem !important;
  padding: 0px !important;
  line-height: 40px;
}

.first-row-agreements .bquarty-sales .ant-table-thead > tr > th {
  border: 1px solid #fff;
}

.first-row-agreements .bquarty-sales .ant-table {
  background: transparent;
}

.first-row-agreements .ant-card-head-wrapper {
  display: -webkit-inline-box;
}

.first-row-agreements .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 85%;
}

.bquarty-sales .bg {
  box-shadow: none !important;
}

.second-row-agreements {
  margin-top: -1rem;
}
.second-row-agreements .ant-table-wrapper {
  width: 100%;
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.second-row-agreements .ant-table,
.second-row-agreements .ant-table-content {
  border-radius: 8px;
}
.second-row-agreements .editable-has-data {
  margin-top: 0;
}

.second-row-agreements .button-full-table {
  top: 32px;
}
.second-row-agreements .ant-table-empty {
  margin-top: 2rem;
}

.edit-table_container-table__2EPYB table {
  border-collapse: collapse;
}

.edit-table_container-table__2EPYB table thead tr th {
  background-color: white !important;
  color: var(--blue-4) !important;
  text-align: center;
  width: calc(1fr);
  padding: 0 !important;
}
.edit-table_container-table__2EPYB table thead tr th:last-child {
  border: none;
  background-color: var(--blue-4) !important;
  color: white !important;
}
.edit-table_container-table__2EPYB table tbody tr:first-child {
  padding: 0 !important;
}
/* .container-table table tbody tr:first-child td:first-child {
  background-color: var(--blue-2) !important;
}

.container-table table tbody tr:first-child td {
  background-color: var(--blue-2) !important;
} */

.edit-table_container-table__2EPYB table tbody tr:first-child td:last-child {
  color: white !important;
}

.edit-table_container-table__2EPYB table tbody tr:last-child td:last-child {
  color: white !important;
  background-color: var(--blue-4) !important;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
}

.edit-table_container-table__2EPYB table tbody tr td:first-child {
  width: 90px;
  font-size: 0.78rem;
  padding: 0 !important;
  padding-left: 5px !important;
  font-weight: 300;
}
.edit-table_container-table__2EPYB th.edit-table_title-month__VrK3e {
  font-size: 0.7rem;
}

.editable th.cell-month,
.editable td.cell-month {
  padding: 0px !important;
  border-bottom: 1px solid #e4e4e4;
}
.editable th.cell-month {
  min-width: 60px;
  border: none;
}

.editable td.cell-month {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
}

th.cell-total {
  min-width: 85px;
  width: 85px;
}
td.cell-total {
  font-size: 0.7rem !important;
  font-weight: 500;
  padding: 5px !important;
}

.cell-month input {
  margin: 0;
  border-radius: none !important;
}

.cell-month .ant-form-item {
  margin: 0;
  padding: 0;
}
.cell-month button {
  height: 100%;
  min-height: 24px;
}

.cell-month input {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  min-height: 24px;
  height: 100%;
  background: transparent;
  vertical-align: bottom;
  padding-right: 2px;
  text-align: right;
  width: 100%;
}

.input-error {
  border: 2px solid red;
}

.div-value {
  padding-right: 2px;
  padding-bottom: 5px;
  text-align: right;
  min-width: 60px;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}
.editable tr td {
  border-color: 1px solid #f0f0f0;
  vertical-align: bottom;
}
.editable-input {
  background: transparent;
  width: 100%;
}
.container-editable .ant-input-number-handler-wrap {
  display: none;
}

.editable td.cell-month > .container-editable {
  background: var(--blue-2);
}
.cell-editable {
  background: var(--blue-2);
}

.editable tr td.cell-month:nth-child(even):not(.cell-editable) {
  background: #fafafa 0% 0% no-repeat padding-box;
}
.editable .ant-table-cell-fix-right {
  background: var(--blue-4);
  border-color: var(-blue-3);
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month {
  color: #707070;
}

td.ant-table-cell.cell-total,
.editable .ant-table-tbody > tr.ant-table-row:hover td.cell-total {
  background: var(--blue-4);
  color: #fff;
  text-align: right;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month:nth-child(even) {
  background: #fafafa 0% 0% no-repeat padding-box;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-editable {
  background: var(--blue-2) !important;
}

td div.container-editable {
  color: #707070;
  min-width: 65px;
  overflow: hidden;
  font-size: 0.7rem;
  text-align: right;
}

td div.container-editable .ant-form-item-control-input {
  min-height: 24px;
}

.editable td {
  font-size: 0.7rem;
  text-align: right;
}

.editable .cell-title {
  font-size: 0.8rem;
  text-align: left;
  height: 35px;
}

.editable .cell-current-period {
  font-weight: 500 !important;
}

.ant-table-empty table tbody tr:last-child td:last-child {
  background-color: transparent !important;
  background-color: initial !important;
}

.button-full-table {
  top: 2px;
  z-index: 1;
  background: transparent !important;
  border: none;
  padding: 0px 8px;
  margin: 0px 5px;
  text-align: center;
  color: #707070 !important;
  border-radius: 0px;
}
.button-full-table svg {
  stroke: black;
}

.container-full-table .ant-modal-content {
  background: transparent !important;
}
.container-full-table .ant-modal-body {
  padding: 0px;
}
.container-full-table .ant-modal-close {
  color: #fff;
  border-radius: 4px;
  padding: 0px;
  font-size: 1rem;
}
.container-full-table .ant-modal-close {
  top: -56px;
}
.container-full-table .ant-modal-close-x {
  font-size: 1.5rem;
}

.container-full-table .ant-modal-close .anticon {
  padding: 10px;
  margin-left: 15px;
}

.container-full-table td div.container-editable {
  width: 78px;
}

.container-full-table .ant-modal-footer {
  display: none;
}

.editable-challenge {
  min-height: 320px;
  margin-top: 0rem;
}

.editable-challenge .ant-table-empty {
  margin-top: 0 !important;
}

.editable-has-data {
  margin-top: -2rem;
}

.editable-challenge-consult .ant-result-image {
  height: 224px;
}

@media (min-width: 1024px) {
  td div.container-editable {
    width: 68px;
  }
}

@media (min-width: 1368px) {
  td div.container-editable {
    width: 100px;
    font-size: 0.9rem;
  }

  .editable td.cell-month,
  th.cell-total {
    font-size: 0.9rem !important;
  }

  .editable tr td.cell-title {
    font-size: 0.8rem !important;
  }
  th.cell-total {
    min-width: 90px;
    width: 90px;
  }
  .editable-challenge .ant-result-image {
    height: 231px;
  }

  .editable-challenge-consult .ant-result-image {
    height: 244px;
  }
}

