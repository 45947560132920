.container-table table {
  border-collapse: collapse;
}

.container-table table thead tr th {
  background-color: white !important;
  color: var(--blue-4) !important;
  text-align: center;
  width: calc(1fr);
  padding: 0 !important;
}
.container-table table thead tr th:last-child {
  border: none;
  background-color: var(--blue-4) !important;
  color: white !important;
}
.container-table table tbody tr:first-child {
  padding: 0 !important;
}
/* .container-table table tbody tr:first-child td:first-child {
  background-color: var(--blue-2) !important;
}

.container-table table tbody tr:first-child td {
  background-color: var(--blue-2) !important;
} */

.container-table table tbody tr:first-child td:last-child {
  color: white !important;
}

.container-table table tbody tr:last-child td:last-child {
  color: white !important;
  background-color: var(--blue-4) !important;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
}

.container-table table tbody tr td:first-child {
  width: 90px;
  font-size: 0.78rem;
  padding: 0 !important;
  padding-left: 5px !important;
  font-weight: 300;
}
.container-table th.title-month {
  font-size: 0.7rem;
}
