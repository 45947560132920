:root {
  /* --warning-color: ; */

  --blue-1: #ebf5fc;
  --blue-2: #c6dff1;
  --blue-3: #7ab0d6; /*input label*/
  --blue-4: #0078b3; /*table columns titles*/
  --blue-5: #00499a; /*background header,footers cards*/
  --gray-light: #00000029; /*borders*/
  --gray-dark: #707070; /*text*/
  --body-bg: var(--blue-1);
  --semaphore-green: #007a32;
  --semaphore-red: #e6001d;
  --sempahore-yellow: #ff9600;
  --text-semaphore-green: #00c14f;
  --text-sepahore-red: #e6001d;
  --main-theme-color: var(--blue-5);
  --success-color: #28a745;
  --error-color: #dc3545;
  --info-color: var(--blue-4);
  --status-on-process: var(--blue-3);
  --status-on-validation: #b55cff;
  --status-approved: var(--semaphore-green);
  --status-rejected: var(--semaphore-red);
  --status-partially-rejected: #f16800;
  --status-on-second-validation: var(--sempahore-yellow);
  /* color para el estatus de segundo nivel de validacion */
  --status-on-second-level-validation: #0ebd96;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(99, 97, 97, 0.671)),
    color-stop(0.72, #bfbfbf),
    color-stop(0.86, rgba(99, 97, 97, 0.671))
  );
}

::-webkit-scrollbar {
  border-radius: 12px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(99, 97, 97, 0.671)),
    color-stop(0.72, #bfbfbf),
    color-stop(0.86, rgba(99, 97, 97, 0.671))
  );
}

body,
.ant-layout {
  color: var(--gray-dark);
  background: var(--body-bg);
  /* font-family: 'Montserrat';
  font-weight: 300; */
}
.ant-layout-header,
.ant-layout-sider {
  background: var(--blue-5);
}
label {
  color: var(--blue-3) !important;
  font-weight: 400;
}
.ant-select {
  color: var(--gray-dark);
}

.ant-form-item-label {
  text-align: left;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--gray-dark);
}

button {
  font-weight: 400;
}

button.ant-btn.ant-btn-default:not([disabled]) {
  background: #0078b3;
  color: #fff;
}

button:disabled {
  color: var(--gray-dark);
  cursor: not-allowed;
}

.ant-form-item-label {
  display: inline-flex;
}

.ant-form-item-label > label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
}

.element-registered {
  border-left: 3px solid var(--semaphore-green);
}
/* .ant-notification-notice-message {
  text-align: justify;
} */
