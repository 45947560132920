.btn-add-user,
.btn-upload {
  margin-right: 5px;
  color: white !important;
  background-color: #003d81 !important ;
  text-align: center;
}
.btn-add-user:disabled,
.btn-upload:disabled {
  color: white !important;
  background-color: #8f8f8f !important ;
}

.btn-action-button {
  margin: -0.5rem;
}
.estatus-switch {
  color: #8f8f8f;
}
/*Orange Switch*/
.half-switch {
  background-color: #ff9600 !important;
}
.half-switch span {
  color: rgba(255, 255, 255, 0) !important;
}
.half-switch .ant-switch-handle {
  left: calc(50% - 9px) !important;
}
