.add-container {
  display: flex;
  align-items: baseline;
}

.add-container p {
  margin-left: 15px;
  margin-bottom: 0;
}

.header-modal {
  display: flex;

  align-items: baseline;
  color: #003d81;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.header-modal p {
  margin-left: 2rem;
  margin-bottom: 0;
}

.form-item {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.form-item p {
  color: #7ab0d6;
}

.form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-space-item {
  width: 100%;
}

.modal-add-product .ant-form-item {
  margin-bottom: 5px;
  min-width: 350px;
  max-width: 350px;
}

span.anticon.anticon-minus-circle.remove-element {
  right: 0;
  top: 15px;
  color: red;
}

.footer-modal {
  margin-top: 1rem;
}
