.form-add-user-input input,
.form-add-user-input label {
  display: block;
}
.form-add-user-input label {
  margin: 0px;
  /* margin: 0px 0px 3px 5px; */
}
.form-add-user-input input {
  width: 100%;
}
.form-add-user-right {
  text-align: right;
}
#form-add-user-common {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-areas:
    'name name last_name last_name second_last_name second_last_name'
    'email email email leaderemail leaderemail leaderemail';
}

#name,
#last_name,
#second_last_name,
#email,
#leader-email {
  align-self: start;
}

#name {
  grid-area: name;
}
#surName {
  grid-area: last_name;
}
#lastName {
  grid-area: second_last_name;
}
#email {
  grid-area: email;
}
#emailLeader {
  grid-area: leaderemail;
}

#userBussinesEstructureSelect {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-areas:
    'region lob subregion territory'
    'zone country . .';
}

#regionsSelect,
#lobsSelect,
#subregionsSelect,
#countriesSelect,
#territoriesSelect,
#zonesSelect {
  align-self: center;
}

#regionsSelect {
  grid-area: region;
}

#lobsSelect {
  grid-area: lob;
}

#subregionsSelect {
  grid-area: subregion;
}

#countriesSelect {
  grid-area: country;
}

#territoriesSelect {
  grid-area: territory;
}

#zonesSelect {
  grid-area: zone;
}

#profileForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-areas: 'profile area . .';
}

#profileSelect,
#areaSelect {
  align-self: center;
}
#profileSelect {
  grid-area: profile;
}
#areaSelect {
  grid-area: area;
}

#clientForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-areas: 'clientSelect . . updateButton';
}
#clientSelect,
#updateButton {
  align-self: center;
}
#clientSelect {
  grid-area: clientSelect;
}

#updateButton {
  grid-area: updateButton;
}

/* input:disabled {
  background-color: #f5f5f5 !important;
} */
