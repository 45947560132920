.editable th.cell-month,
.editable td.cell-month {
  padding: 0px !important;
  border-bottom: 1px solid #e4e4e4;
}
.editable th.cell-month {
  min-width: 60px;
  border: none;
}

.editable td.cell-month {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
}

th.cell-total {
  min-width: 85px;
  width: 85px;
}
td.cell-total {
  font-size: 0.7rem !important;
  font-weight: 500;
  padding: 5px !important;
}

.cell-month input {
  margin: 0;
  border-radius: none !important;
}

.cell-month .ant-form-item {
  margin: 0;
  padding: 0;
}
.cell-month button {
  height: 100%;
  min-height: 24px;
}

.cell-month input {
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  min-height: 24px;
  height: 100%;
  background: transparent;
  vertical-align: bottom;
  padding-right: 2px;
  text-align: right;
  width: 100%;
}

.input-error {
  border: 2px solid red;
}

.div-value {
  padding-right: 2px;
  padding-bottom: 5px;
  text-align: right;
  min-width: 60px;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}
.editable tr td {
  border-color: 1px solid #f0f0f0;
  vertical-align: bottom;
}
.editable-input {
  background: transparent;
  width: 100%;
}
.container-editable .ant-input-number-handler-wrap {
  display: none;
}

.editable td.cell-month > .container-editable {
  background: var(--blue-2);
}
.cell-editable {
  background: var(--blue-2);
}

.editable tr td.cell-month:nth-child(even):not(.cell-editable) {
  background: #fafafa 0% 0% no-repeat padding-box;
}
.editable .ant-table-cell-fix-right {
  background: var(--blue-4);
  border-color: var(-blue-3);
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month {
  color: #707070;
}

td.ant-table-cell.cell-total,
.editable .ant-table-tbody > tr.ant-table-row:hover td.cell-total {
  background: var(--blue-4);
  color: #fff;
  text-align: right;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-month:nth-child(even) {
  background: #fafafa 0% 0% no-repeat padding-box;
}

.editable .ant-table-tbody > tr.ant-table-row:hover > td.cell-editable {
  background: var(--blue-2) !important;
}

td div.container-editable {
  color: #707070;
  min-width: 65px;
  overflow: hidden;
  font-size: 0.7rem;
  text-align: right;
}

td div.container-editable .ant-form-item-control-input {
  min-height: 24px;
}

.editable td {
  font-size: 0.7rem;
  text-align: right;
}

.editable .cell-title {
  font-size: 0.8rem;
  text-align: left;
  height: 35px;
}

.editable .cell-current-period {
  font-weight: 500 !important;
}

.ant-table-empty table tbody tr:last-child td:last-child {
  background-color: initial !important;
}

.button-full-table {
  top: 2px;
  z-index: 1;
  background: transparent !important;
  border: none;
  padding: 0px 8px;
  margin: 0px 5px;
  text-align: center;
  color: #707070 !important;
  border-radius: 0px;
}
.button-full-table svg {
  stroke: black;
}

.container-full-table .ant-modal-content {
  background: transparent !important;
}
.container-full-table .ant-modal-body {
  padding: 0px;
}
.container-full-table .ant-modal-close {
  color: #fff;
  border-radius: 4px;
  padding: 0px;
  font-size: 1rem;
}
.container-full-table .ant-modal-close {
  top: -56px;
}
.container-full-table .ant-modal-close-x {
  font-size: 1.5rem;
}

.container-full-table .ant-modal-close .anticon {
  padding: 10px;
  margin-left: 15px;
}

.container-full-table td div.container-editable {
  width: 78px;
}

.container-full-table .ant-modal-footer {
  display: none;
}

.editable-challenge {
  min-height: 320px;
  margin-top: 0rem;
}

.editable-challenge .ant-table-empty {
  margin-top: 0 !important;
}

.editable-has-data {
  margin-top: -2rem;
}

.editable-challenge-consult .ant-result-image {
  height: 224px;
}

@media (min-width: 1024px) {
  td div.container-editable {
    width: 68px;
  }
}

@media (min-width: 1368px) {
  td div.container-editable {
    width: 100px;
    font-size: 0.9rem;
  }

  .editable td.cell-month,
  th.cell-total {
    font-size: 0.9rem !important;
  }

  .editable tr td.cell-title {
    font-size: 0.8rem !important;
  }
  th.cell-total {
    min-width: 90px;
    width: 90px;
  }
  .editable-challenge .ant-result-image {
    height: 231px;
  }

  .editable-challenge-consult .ant-result-image {
    height: 244px;
  }
}
