#businessEstructureFilters {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}
#archiveBussinesEstructureSelect > div {
  min-width: 130px;
  max-width: 170px;
  margin-left: 5px;
}
.ant-table-wrapper::after {
  display: none !important;
}
#archiveBussinesEstructureSelect .ant-table-content {
  min-height: 525px;
  min-height: 525px;
  overflow: scroll;
}
.ant-btn.delete-catalog__button {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  box-shadow: none;
  cursor: pointer;
}

.ant-btn.delete-catalog__button svg {
  color: #004a96;
}
