.ant-table-thead > tr > th.bg-account {
  background-color: #0078b3 !important;
  color: white !important;
  text-align: left !important;
  padding: 10px 5px !important;
}

.ant-table-thead > tr > th.ant-table-row-expand-icon-cell {
  background-color: #0078b3 !important;
}

.algoooo > .ant-table-container table > thead > tr:first-child th:first-child {
  background-color: #0078b3 !important;
}

.ant-table-thead,
th.bg-blue {
  background-color: #0078b3 !important;
  color: white !important;
  text-align: left !important;
}
.size-icon {
  font-size: 30px;
}
.size-icon :hover {
  cursor: pointer;
}
.ant-table-thead > tr > th.bg-account,
.ant-table-tbody > tr > td.bg-account,
.ant-table tfoot > tr > th.bg-account,
.ant-table tfoot > tr > td.bg-account {
  position: relative;
  padding: 5px 5px;
  overflow-wrap: break-word;
}

.icon-coursor :hover {
  cursor: pointer;
}

.icon-hasntImage {
  color: #9b9b9b;
  opacity: 0.4;
}

.icon-hasImage {
  color: '#00499A';
}
.ant-row.manita :hover {
  cursor: pointer;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #ffffff;
  padding: 0;
}

/* .anticon-zoom-in {
  display: none;
}

.anticon-zoom-out {
  display: none;
} */

.anticon-rotate-left {
  display: none;
}
.anticon-rotate-right {
  display: none;
}

.ant-image-preview-operations {
  padding-top: 65px;
  padding-right: 28px;
  padding-bottom: 10px;
  background: none;
}
