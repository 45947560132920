
/* width */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar {
    width: 10px;
}

/* Track */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
div.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft > div > div:nth-child(2)::-webkit-scrollbar-thumb:hover {
    background: #555; 
}