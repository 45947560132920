.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 130px;
}

.title{
    font-size: 14px;
    margin-bottom: 2px;
}

.battery{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 35px;
}

.battery p{
    font-size: 14px;
    margin: 1px 0 0 0;
}

.container-battery{
    border-bottom: 4px solid #969696b3;
    border-top: 4px solid #969696b3;
    width: 40%;
    height: 100%;
    max-height: 40px;
    max-width: 60px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    margin: 0 5px 0 12px;
}

.percentage-battery{
    height: 100%;
    width: calc(80%/4);
    max-width: 15px;
}

.percentage-battery-last{
    height: 13px;
    width: 7%;
    max-width: 7px;
}