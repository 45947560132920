.ant-layout-content {
  background-color: #ebf8ff;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item {
  margin: 0;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-active {
  background: #002140;
  color: white;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 1.25rem !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 1.25rem !important;
}

.ant-menu-inline-collapsed {
  width: 55px;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected,
.sidenav-footer:hover,
.sidenav-footer-collapsed:hover {
  background: #002140;
  color: white;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #002140;
  color: white;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0 !important;
}

.sidenav-wrapper {
  padding: 2rem;
  height: 90%;
}
.sidenav-wrapper-collapsed {
  padding: 1rem;
  height: 90%;
}
.sidenav-header {
  text-align: center;
}
.sidenav-logo {
  width: 100%;
}
.sidenav-body {
  height: 100%;
  margin-top: 25px;
}
.sidenav-footer,
.sidenav-footer-collapsed {
  border-top: 1px solid white;
  bottom: 60px;
  cursor: pointer;
  padding-bottom: 0.5rem;
  padding-top: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 100ms linear background-color;
  width: 100%;
}
.sidenav-footer {
  text-align: start;
  padding-left: 1.3rem;
}
.sidenav-footer-collapsed {
  margin-left: -1rem;
}
.collapse-button {
  border: none;
  background: none;
  color: white;
  outline: none;
  width: 110%;
  margin-left: -10px;
  cursor: pointer;
}
.collapsed-collapse-button {
  border: none;
  background: none;
  color: white;
  outline: none;
  margin-left: -5px;
  cursor: pointer;
}
.logout-button {
  background: none;
  cursor: pointer;
  border: none;
  color: white;
  outline: none;
}
.nav-item,
.nav-item-collapse {
  /* border-right: 2px solid white; */
  margin-top: 0.3rem;
  line-height: 0.2em;
}
.nav-item > a,
.nav-item-collapse > a {
  width: 100%;
  color: #fbfbfb;
}
.nav-item > a > p,
.nav-item-collapse > a > p {
  margin: 1em 0px 1.2em 0px;
  line-height: 1.3em;
}
.nav-item-collapse {
  text-align: center;
  margin: 10px 0px;
}

.nav-container-subitem {
  position: relative;
  width: 180px;
  right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #003d81;
  padding: 0.9em 0px 0.5em 55%;
}

.nav-container-subitem a {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}
.nav-container-subitem > p {
  padding-left: 30%;
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}

.nav-subitem > p {
  padding-left: 30%;
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}
.nav-subitem-collapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #003d81;
  position: absolute;
  z-index: 20;
  padding: 14px;
  left: 43px;
  top: 98px;
}
.nav-subitem-collapsed a {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
  white-space: nowrap;
}
.nav-subitem-collapsed p {
  margin: 6px 0;
}

.nav-subitem-collapsed > p {
  border: none;
  color: #f0f2f5;
  font-size: 0.8em;
}

.module-nav {
  font-weight: 400;
}
.module-selected {
  color: #f0f2f5 !important;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #0078b3 !important;
}
.vertical-div {
  width: 2px;
  height: auto;
  background-color: #fbfbfb;
  margin: 0px 20px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ant-layout-header {
  height: 50px;
}
.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 3px 15px 3px 0;
  border-right: 2px solid white;
  margin-right: 15px;
}

.navbar-logo-short {
  width: 100px;
}

.navbar-actions {
  margin-left: auto;
  line-height: normal;
}

.notification-icon {
  color: white;
  font-size: 1.3em;
  margin: 10px 0px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #00499a !important;
}

.position-sidebar {
  position: absolute;
  z-index: 102;
  height: calc(100% - 50px);
}
