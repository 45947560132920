.login-wrapper {
  background-color: #00499a;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 5rem 5rem 0px 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.logo {
  height: 0;
  width: 400px;
  background-color: #00499a;
  float: left;
  padding: 50px 100px 100px 115px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.login-box {
  position: fixed;
  right: 10vw;
  background-color: #fefefe;
  width: 30vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: 70vh;
  max-height: 450px;
  padding: 25px;
  text-align: center;
  letter-spacing: 0.1em;
}
.login-box > h1,
.login-box > h2,
.login-box > span,
.login-box > p {
  color: #00499a;
  font-family: 'Montserrat', sans-serif;
}
.login-box > h1 {
  font-weight: 700;
  margin: 0px 0px 15px 0px;
}
.login-box > h2 {
  font-weight: 500;
  margin: 0px 0px 35px 0px;
}
.login-box > p {
  color: #474747;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  margin-left: 0.5em;
  margin-bottom: 0.3em;
}
.login-box > span {
  text-align: end;
  margin: 0px 0px 1.4rem 0px;
  font-size: 1.3em;
}
.login-box > button {
  float: right;
  background: #00499a;
  border: none;
  width: 120px;
  margin: 2rem 0px;
  padding: 0.2em 0.1em 0.3em 0.1em;
  color: white;
  font-size: 1.1em;
  border-radius: 1px;
  cursor: pointer;
}
.login-btn_disabled {
  background: rgba(0, 73, 154, 0.5) !important;
  cursor: pointer;
}

.span-reset-password {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.span-reset-password button {
  background-color: white;
  border: none;
  color: #00499a;
  font-size: 12px !important;
  text-decoration: underline;
  margin: 0 !important;
  cursor: pointer;
}
.description-reset-password {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.description-reset-password p {
  color: #00499a;
  font-size: 12px !important;
}

.container-icon-back {
  display: flex;
  justify-content: flex-start;
}
