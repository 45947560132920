.ant-input-number {
  border: 0px solid #d9d9d9;
  -webkit-appearance: none;
}

.ant-input-number:hover {
  border-color: #d9d9d9;
}

.ant-input-number-focused {
  border: 0px solid #d9d9d9;
  -webkit-appearance: none;
}

.ant-input-number {
  width: 100%;
}
