#drawerManageImages > .ant-drawer-content-wrapper {
  margin-top: 0px !important;
  width: 30vw !important;
}
.ant-drawer-body {
  padding: 0;
  background-color: #f5f5f7 !important;
}
#spaceManageImage > .ant-space-item {
  margin-bottom: 0px !important;
}
#spaceManageImageDiv > .ant-radio-wrapper {
  display: block;
}
.ant-checkbox {
  margin-left: 20px;
}

span.ant-radio + span {
  color: black !important;
}
.imgDrawerManageImage {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 36%;
  max-width: 64%;
  max-height: 85%;
  z-index: 1;
}
