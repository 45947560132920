#ShoppingByFamilyTableConsult tfoot > tr > th,
.ant-table tfoot > tr > td {
  background: #004a96 !important;
  color: white;
}

#ShoppingByFamilyTableConsult td {
  padding: 5px 16px;
}

#ShoppingByFamilyTableConsult thead tr th {
  color: #004a96;
}
