.form-catalog-input input,
.form-catalog-input label {
  display: block;
}
.form-catalog-input label {
  margin: 0px 0px 3px 5px;
}
.form-catalog-input input {
  width: 100%;
}
.upload-xls-button {
  margin: 16px;
  margin-top: 30px;
  color: white;
  border-radius: 5px;
  width: 220px;
  height: 35px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: #247ba9;
  cursor: pointer;
}

.disabled-upload-xls-button {
  margin: 16px;
  margin-top: 30px;
  color: white;
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
  border-radius: 5px;
  width: 220px;
  height: 35px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: #247ba9;
}

.null div input {
  color: black;
  border-radius: 2px;
  font-weight: 330;
  border: 1px solid rgb(214 213 213) !important;
}
.ant-input-number-input:disabled {
  background-color: #f5f5f5 !important;
  /*color: black;*/
}

.deleteButton {
  position: absolute;
  margin-top: 32px !important;
  margin: 10px 16px 16px;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 220px;
  font-size: 1.1em;
  border: none;
  font-weight: 600;
  background-color: rgb(0, 74, 150);
}

.sumary-duplicated .ant-modal-confirm .ant-modal-confirm-btns {
  float: none !important;
  margin-top: inherit !important;
  display: flex;
  justify-content: space-around;
}
