.container-label{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    padding: 8px 4px;
    color: white;
    margin: 12px 0;
}