.total-row {
  text-align: left;
  background-color: #0078b3;
  color: white;
}

.total-number {
  color: white;
  text-align: center !important;
}

th.row-head {
  background-color: #f2f2f2 !important;
  text-align: left !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  font-weight: 500 !important;
}

th.row-head-color {
  background-color: #f2f2f2 !important;
}

td.row-head-color {
  height: 5px !important;
}

.custom-table th,
.custom-table td {
  padding-left: 0px !important;
  padding-right: 7px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow-wrap: break-word;
}

.custom-table .ant-result-image svg {
  transform: none !important;
}
.custom-table .ant-result-subtitle {
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  padding-top: 60px;
}
