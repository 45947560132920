.container{
    width: 110px;
}
.container p{
    font-size: .9rem;
    margin: 0;
}


.container span{
    color: red;
    font-weight: bold;
}