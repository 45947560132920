.title-container{
    width: 100%;
    height: 35%;
    background-color: #0078B3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
}

.title-container p{
    font-size: 1.2rem; 
    margin: 0;
}
.children-container{
    width: 100%;
    height: 65%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
