
.title-icon-card {
    background-color: white;
    border: 6px solid rgb(0, 73, 154);
    min-height: 8rem;
}

.title-icon-card .ant-row {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 4px;
}

.title-icon-card .title {
    padding: 0 1rem;
    color: rgb(0, 73, 154);
    font-weight: 700;
    font-size: 1.1em;
    text-align: center;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-icon-card .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00499A;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}
