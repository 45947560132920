.continer-breadcrums-profile {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.container-profile p {
  margin: 0;
  color: white;
}
.profile {
  cursor: pointer;
}

.continer-update-password {
  position: absolute;
  height: 35px;
  padding: 0 12px;
  top: 48px;
  background-color: white !important;
  border: 2px solid #00499a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.continer-update-password p {
  color: #00499a;
  margin: 0 !important;
}
