.browserBtn {
  margin-left: calc(90vw - 202px);
  margin-right: 10vw;
  transform: translateY(-15px);
  margin-bottom: -15px;
  z-index: 2;
}
.mobileBtn {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50vh;
  left: 50vw;
}
.containerStyle {
  margin: 0;
  margin-top: 15px;
  padding: 0;
  padding-bottom: 80px;
}
.pdfStyle {
  margin-top: 7px;
  width: 70vw;
  min-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.activePdfBackground {
  background-color: rgb(112, 112, 112);
}

.btn {
  letter-spacing: 2px;
  font-weight: 600;
  fill: white !important;
}
.icon {
  filter: invert(100%) sepia(1%) saturate(6945%) hue-rotate(119deg) brightness(114%) contrast(100%);
  margin-right: 5px;
  transform: translateY(2px);
}
