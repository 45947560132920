.container-modal {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00499a;
  margin-bottom: 20px;
}
.container-modal-title span {
  font-size: 18px;
  margin: 0 20px 0 0;
}
.container-modal-title p {
  font-size: 18px;
  margin: 0;
}
.container-modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}
.container-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.container-paragraph {
  margin-top: -15px;
}
.container-form {
  display: flex;
  align-items: center;
  width: 70%;
}

.container-pass {
  border: solid 2px blue;
  border-radius: 5px;
  margin-bottom: 15px;
}
